:after,
:before,
body,
html {
  margin: 0;
  padding: 0
}

html {
  scroll-behavior: smooth;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

body,
html {
  font-family: "Inter", sans-serif !important;

  font-size: 15px;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
  scroll-behavior: smooth
}



a {
  color: inherit;
  text-decoration: none
}

#root {
  overflow: hidden;
}

.tags-input-container {
  border: 2px solid #000;
  padding: .5em;
  border-radius: 3px;
  width: min(80vw, 600px);
  margin-top: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: .5em;
}

.tag-item {
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: .5em .75em;
  border-radius: 20px;
}

.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: .5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: .5em 0;
  border: none;
  outline: none;
}

:root {
  --main-paragragh-laptop: 14px;
  --main-paragragh-tablet: 15px;
  --main-paragragh--mobile: 16px;
  --main-card-heading-mobile: 21px;
  --main-card-heading-tablet: 22px;
  --main-card-small-heading-tablet: 14px;
  --main-card-heading-latop: 22px
}



@media (min-width: 576px) {
  .container-fluid {
    max-width: 100%;
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
}

@media (min-width: 768px) {
  .container-fluid {
    max-width: 100%;
    padding-left: 3% !important;
    padding-right: 3% !important;
  }
}

@media (min-width: 992px) {
  .container-fluid {
    max-width: 1200px;
    padding-left: 3% !important;
    padding-right: 3% !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    max-width: 1300px;
    padding-left: 3% !important;
    padding-right: 3% !important;
  }
}

@media (min-width: 1400px) {
  .container-fluid {
    padding-left: 3% !important;
    padding-right: 3% !important;
    max-width: 1450px;
  }
}


.modal_discount_open {
  background: linear-gradient(100deg, #7939CC 0.83%, #21599C 50.4%, #008 102.84%);
  /* padding: 2px; */
  color: #fff;
  font-size: 13px;
  text-transform: capitalize;
  text-align: center;
  padding: 3px 0;
  width: 100%;
  margin: 0;
}

.modal_discount_open p {

  margin: 0;
}

:focus-visible {
  outline: none !important;
}

.off_canvas,
.home_slides {
  display: none;
}

.margin_top_bottom {
  margin-top: 4rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loader {
  width: 22px;
  height: 22px;
  border: 3px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.loading-calculator {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.loading-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.custom-loader {
  width: 50px;
  height: 50px;

  display: grid;
  border-radius: 50%;
  mask: radial-gradient(farthest-side, #0000 40%, #000 41%);
  -webkit-mask: radial-gradient(farthest-side, #0000 40%, #000 41%);
  background: linear-gradient(0deg, #766df480 50%, #766df4ff 0) center/4px 100%,
    linear-gradient(90deg, #766df440 50%, #766df4bf 0) center/100% 4px;
  background-repeat: no-repeat;
  animation: s3 1s infinite steps(12);
}

.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.custom-loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

::-webkit-scrollbar {
  width: 6px;
  /* display: none; */
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  /* display: none; */
}

::-webkit-scrollbar-thumb {
  background: #7939CC;
  border-radius: 10px;
  /* display: none; */
}


.navbar .nav-item .show>.dropdown-menu {
  display: block;
}

.navbar-nav .active {
  color: #7939cc !important;
  border-bottom: 2px solid #7939cc;
}

.navigation_Section .navbar-nav .active {
  border-bottom: 2px solid #ffffff !important;
}

.navbarSection,
.navigation_Section {
  position: absolute;
  width: 100%;
  top: 0;

}

p {
  padding-top: 0;
  cursor: pointer;
}


.solution_dropdown div {
  width: 500px;
}

.trux-logo {
  width: 240px;
}

.dropdown-item {
  color: #fff !important;
}

nav.navbar.navbar.navbar-expand-lg.navbar-light {
  padding: 0;
  z-index: 10;
}

.logins-buttons {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  align-items: center;

}

/* .navbar-section{
  position: absolute;
  width: 100%;
} */
Nav .nav-link {
  white-space: nowrap;
  font-size: 17px;
  color: #ffffff !important;
  color: #000000 !important;
  font-family: "Inter", sans-serif !important;
  margin-right: 20px;
  font-weight: 500;
}

Nav .nav-link:hover {
  color: #7939cc !important;
}

.navbar {
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.discount.sticky {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  color: #000000 !important;

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  filter: invert(0);
}

.navbar.sticky {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  color: #000000 !important;
  position: fixed;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
  top: 3%;
  width: 100%;
  z-index: 100;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  filter: invert(0);
}

.hero-banner h1 {
  color: #ffffff;
}

.solution_body {
  /* width: 700px; */
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.solution_image img {
  /* background-color: #efecff; */
  border-radius: 10px;
  margin-top: 20px;
}

.solution_image img {
  width: 130px;
}

.solution_items svg,
.resource_items svg {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.solution_items {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: repeat(3, 50px);
  row-gap: 10px;
  column-gap: 60px;
}

.resource_items {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: repeat(2, 50px);
  column-gap: 100px;
  row-gap: 10px;
}

.resource_body {
  /* width: 500px; */

  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.hero-banner h1 {

  font-size: 45px;
  color: #7939CC;
  /* font-weight: bold; */
  /* background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  color: transparent;
  -webkit-background-clip: text; */
  font-weight: 600;
}

.hero-banner h1 span {
  font-weight: 500;
  color: #4656A3
}

.hero-banner h1 p {
  color: #36CB72;
  font-weight: 400;
}

.banner-content {
  padding-top: 2rem;
  text-align: center;
}

.home_slider {
  z-index: 1;
  position: relative;

}

.hero-banner::after {
  content: "";
  width: 300px;
  height: 300px;
  border-radius: 400px;
  opacity: 0.5;
  background: #36CB72;
  filter: blur(125px);
  position: absolute;
  bottom: 0;
  right: -10%;

}

.track-shipments {
  width: 100%;


  border-radius: 50px;

  backdrop-filter: blur(21px);
  padding: 5px 10px;
  background: #FFF;
  box-shadow: #dcc0ff 0px 2px 8px;
  border: 2px solid #dcc0ff;
}

.track-shipments .form-select {
  color: #7939cc;
  border: none;
  margin-top: 3px;

}

.tracking_shipment input,
.tracking_shipment input:focus {
  border: none !important;
  background-color: none !important;
  line-height: 2;

}

.error-message {
  margin: 0;
  font-size: 14px;
  color: red !important;
}

.form-check-input:checked {
  background-color: #7939CC !important;
  border-color: #dcdbdb !important;
}

.border-horizontal {
  border-radius: 8px;
  background: rgba(26, 26, 26, 0.11);
  width: 5px;
  height: 40px;
  margin: auto;
}

.blog-search {
  position: relative;

}

.blog-search input {
  line-height: 2.3;
  border: 2px solid #7939cc;

}

.button-blog-search {
  position: absolute;
  right: 0;
  margin: 6px;
  background: linear-gradient(89deg, #7939cc 2.76%, #008 95.54%);
}

/* .track-shipments label {
  margin-right: 41px;
  margin-bottom: 1rem;
  font-size: 14px;
  color: #000;
}

.track-shipments label input[type="radio"] {
  width: 2rem !important;
  height: 1rem !important;
}

.nav-tabs li {
  width: 50%;
}

.nav-tabs .nav-link {
  width: 100%;
  color: #fff;
  border: 1px solid #fff !important;
  background-color: #000;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #7939cc !important;
  font-weight: bold;
}

.nav-tabs .nav-link:hover {
  color: #fff !important;
} */

.fl-wrap {
  float: left;
  width: 100%;
  position: relative;
}

.bbb-wrapper .subcribe-form p {
  color: #fff;
  font-size: 14px;
}

.bbb-wrapper .subcribe-form form {
  max-width: 525px;

  position: relative;
}

.bbb-wrapper .subcribe-form #subscribe input {
  width: 100%;
  height: 42px;
  background: #f6f6f6;
  border: 1px solid #dfdfdf;

  border-radius: 1px;
  padding: 0 133px 0 30px;
}

.bbb-wrapper .subcribe-form #subscribe #subscribe-button {
  height: 30px;
  padding: 0 30px;
  border-radius: 3px;
  border: none;
  position: absolute;
  right: 6px;
  top: 6px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.subscribe-message {
  color: #fff;
  float: left;
  margin-top: 10px;
  text-align: left;
  font-weight: 500;
  line-height: 19px;
}

.color-bg {
  background: #7939cc;
}

#subscribe-button:hover {
  background: #2c3b5a;
  color: #fff;
}

.subcribe-form.fl-wrap h6 {
  text-align: center;
  text-transform: uppercase;
}

.homeair-section ul,
.footer-section ul {
  list-style: none;
  padding: 0 !important;
}

.hero-points {
  text-align: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 5px;
  background: #fff;

  padding: 10px;

  font-weight: 500;
  height: 140px;

  border-radius: 10px;

  border: 1px solid #fff;
}

.hero-points p {
  border-top: 1px solid #000 !important;
  border-bottom: 1px solid #000 !important;
  color: #000000;
  font-size: 14px;
  padding: 5px;
}

.hero-points img {
  margin-bottom: 5px;
  width: 50px;
  height: 40px;
}

.icon-bg {
  width: 50px;
  height: 50px;
  background: #ffff;
  margin-right: 10px;
  border-radius: 100%;
  text-align: center;
}

.featured-card img {
  width: 50px;

  padding: 10px;
}

.featured-card {
  color: #fff;

  width: 100%;
  margin: 10px 0;

  display: flex;
  justify-content: space-between;
  transition: 0.5s;
}

.featured-card h3 {
  font-size: 20px;
  font-weight: 700;
  color: #0b0757;
}

.featured-card p {
  font-size: 13px;
  color: #333;
}

.process-section .client {
  width: 50%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 32px;
  padding: 40px 30px;
  background: #d3f4fd;
  margin: 10px;
  text-align: center;
}

.process-section .Orders {
  width: 35%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 32px;
  padding: 40px 30px;
  background: #e3ddf8;
  margin: 10px;
}

.process-section .Experts {
  background: #c0dffb;
  width: 35%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 32px;
  padding: 40px 30px;

  margin: 10px;
}

.process-section .Deliveries {
  width: 50%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 32px;
  padding: 40px 30px;
  background: #d3f4fd;
  margin: 10px;
  text-align: center;
  background: #f2e5f1;
}

.process-section .Integrations {
  width: 50%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 32px;
  padding: 40px 30px;
  background: #d3f4fd;
  margin: 10px;
  text-align: center;
  background: #8ce8d7;
}

.process-section .pincodes {
  width: 35%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 32px;
  padding: 40px 30px;
  margin: 10px;
  background: #d9cfd2;
}

.process-section .client:hover,
.process-section .pincodes:hover,
.process-section .Integrations:hover,
.process-section .Deliveries:hover,
.process-section .Experts:hover,
.process-section .Orders:hover {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  font-weight: bold;

}

.working-featured {
  border-radius: 10px;
  width: 100%;

  text-align: center;
  position: relative;
}

.working-featured h3 {
  font-size: 35px;
  font-weight: 600;
  text-transform: capitalize;
  color: #1441a9;
  display: inline-block;
}

.working-featured h3:after {
  content: " ";
  position: absolute;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  height: 4px;
  width: 80%;
  left: 0;
  right: 0;

  bottom: -15px;
  left: 0;
  top: 92px;
  margin: auto;
  border-radius: 20px;
}

.working-featured p {
  font-size: 18px;
  color: #000000;
}

.working-featured span {
  color: transparent;
  font-size: 57px;
  /* display: inline-block; */

  float: right;
  border: 1px solid;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.2);
}

.about-bg h1 {
  display: flex;
  font-weight: 900;
  font-style: italic;
  color: #1f334a;
  justify-content: space-between;
  font-family: "Inter", sans-serif !important;
  font-size: 50px;
}

.about-bg p {
  text-align: justify;
  font-size: 14px;
}

.about-bg p::before {
  content: "";
  display: block;
  width: 2px;
  z-index: 1;
  position: absolute;
  height: 330px;
  background-color: #7154fd;
  margin-left: -16px;
}

.logo-partner-section img {
  width: 100px;
  transition: transform 0.3s ease-in-out;
}

.logo-partner img:hover {
  transform: rotate(360deg);
}

.footer-section {
  background: #000;
  background: linear-gradient(278deg,
      #7939cc 0.45%,
      #4656a3 49.52%,
      #233869 101.17%);
  color: #fff;
  border-radius: 20px 20px 0 0;
}

.footer-section .monuments_footer {
  background: linear-gradient(179deg, #FFF 8.99%, rgba(255, 255, 255, 0.00) 58.53%), radial-gradient(231% 135.8% at 0.9% 2.98%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.22) 100%);
  backdrop-filter: blur(21px);
  padding-top: 2rem;
  position: relative;
}

.footer-section .monuments_footer h2 {
  margin: auto;
  color: #4656A3;
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  left: 0;
  right: 0;
  text-transform: capitalize;

}

.footer-section ul li {
  line-height: 2.3;

  font-size: 14px;

  cursor: pointer;
  color: #e8e8e8;
  transition: all 300ms ease;
  position: relative;
}

.footer_accordion {
  display: none;
}

.social-media {
  text-align: center;
  margin: 1rem 0;
  border-radius: 8px;
  border: 3px solid #6b80ce;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.15) 100%);
  backdrop-filter: blur(21px);
  padding: 10px;
}

.social-media img {
  width: 30px;

  margin: 0 15px;
  cursor: pointer;
  transition: 0.5s;
}

.social-media img:hover {
  transform: translateY(-10px);
}

.man-box {
  width: 25%;
  position: absolute;
  top: 0;
  left: 15%;
}

.newsletter img {
  width: 50px;
  height: 50px;
  margin: 10px;
}

.newsletter h4 {
  font-weight: bold;
  background: linear-gradient(-225deg, #00b5ce 0%, #00cab9 48%, #00d6ae 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.newsletter p {
  margin: 0 !important;
}

.bg-man {
  position: relative;
  margin-top: 7rem;
}

.footer-logo {
  width: 60%;
}

.footer-section ul p {
  font-size: 14px;
  font-weight: 300;
  text-align: justify;
}

.footer-section h3 {
  font-size: 22px;
  margin-bottom: 2rem;
}

.footer-section ul i {
  margin-right: 10px;
  font-size: 20px;
}

.footer-section li:hover:before {
  width: 100%;
}

.footer-section ul li:hover {
  color: #ffffff;
}

.footer-section ul li:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: yellow;
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.step-part {
  display: table !important;
}

.primum-steps {
  display: table-cell;
  vertical-align: middle;
}

.newsletter {
  padding: 2rem;

  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.newsletter form input {
  line-height: 2;
}

.newsletter form button {
  margin-top: 20px;
}

.procees-image {
  animation: float 4s ease-in-out infinite;
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

.about-bg {
  position: relative;
}

.order-status-section h4,
.process-section h4,

.rate-calculator2 h4,
.benifits_customer h4,
.herocard-section h4,
.services_componets h4,
.conversionslider h4,
.integration h4,
.blog-section h4,
.stages_section h4 {


  font-size: 50px;
  font-weight: bold;
  color: #0b0757;
  margin-bottom: 2rem;
}

.order-status-section h4>span,
.process-section h4>span,
.blog-section h4>span,
.rate-calculator2 h4 span,
.benifits_customer h4>span,
.herocard-section h4 span,
.services_componets h4>span,
.conversionslider h4 span,
.integration h4 span,
.dashboard_section h4 span {
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.process-section,
.logo-partner-section,
.testimonial-heading,
.herocard-section,
.rate-calculator,
.service-section,
.invoice-section,
.integration,
.partner-trust,
.blog-section,
.benifits_customer {
  position: relative;
}

.disabled_componets {
  display: none;
}

.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.video-container video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.2);
}

.button-54 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-54:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

.login-btn {
  background-color: #7939cc !important;
  box-shadow: #fff 4px 4px 0 0, #7939cc 4px 4px 0 1px;
  font-weight: 600;
  font-size: 13px;
}

.icons-number img {
  color: #866df3;
  left: 14px;
  width: 40px;
  height: 40px;
  z-index: 2;
}

.testimonal-card {
  margin-bottom: 10px;
  padding: 40px;
  /* width: 100%; */
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  background: #f8f9fa;
  /* height: 300px; */
  border-radius: 10px;
  color: #000000;
  position: relative;
  overflow: hidden;
}

.testimonal-card::before {
  content: "";
  background: url(/public/image//testimonial_element_1.svg);
  position: absolute;
  width: 65px;
  height: 150px;
  right: 0;
  top: 0;
  z-index: 1;
  background-repeat: no-repeat;

}

.testimonal-card::after {
  content: "";
  background: url(/public/image//testimonial_element_2.svg);
  position: absolute;
  width: 150px;
  height: 200px;
  left: 0;
  top: 0;
  z-index: 1;
  background-repeat: no-repeat;

}

.testimonal-card_inside {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  z-index: 2;
  position: relative;
}

.react-multiple-carousel__arrow {
  border: 2px solid #fff !important;
}

.testimonal-card h6 {
  font-weight: bold;
}

.testimonal-card h6 {
  font-weight: bold;
}

.testimonal-card small {
  color: #af40ff;
}

.testimonal-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: none !important;
}

.tes-content {
  /* margin-top: 20px; */
  width: 80%;
  position: relative;
}

.tes-content h5 {
  margin-top: 20px;

  font-weight: 600;
}

.tes-content h1 i {
  color: #4656a3;
  font-size: 40px;

}

.tes-content p {
  font-size: 18px;
}

.testimonials-section {
  background: #4656a3;
  width: 100%;

  position: relative;
}

.testimonials-section h3 {
  font-size: 27px;
  font-weight: 600;

  background: linear-gradient(-225deg, #ac32e4 0%, #7918f2 48%, #4801ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.react-multi-carousel-dot--active button {
  background: #8b21ed !important;
}

.react-multi-carousel-dot button {
  border-color: #ffffff !important;
}

.phone {
  display: flex;
  justify-content: space-between;
}

.grow ul li {
  line-height: 2;
  font-weight: 600;
}

.grow ul li::before {
  content: "\f058";
  font-family: FontAwesome;
  display: inline-block;
  width: 1.3em;
  color: #7939cc;
}

.rate-content h6 {
  color: #0046a1;
  font-size: 24px;
  font-weight: 600;
}

.rate-content p {
  font-size: 14px;
}

.rate-calculator2 {
  padding: 4rem 0;
}

.rate-calculator2 img {
  width: 55%;
}

.button-56 {
  align-items: center;
  background-color: #7939cc;
  border: 2px solid #fff;
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-56:hover:after {
  transform: translate(0, 0);
  background-color: transparent;
  border-radius: 8px;
  content: "";
  display: block;
  height: 45px;
  border: 2px solid #4656a3;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(5px, 7px);
  transition: transform 0.2s ease-out;
  z-index: -1;
}

.button-56:active {


  /* background-color: #7939cc; */
  outline: 0;
}

.button-56:hover {
  outline: 0;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.banners-top {
  position: relative;
}

.banner-title {
  position: absolute;
  top: 30%;
  left: 10%;
}

.banner-title h2 {
  font-size: 55px !important;
  color: #7939cc;
}

.modal-header {
  padding: 0 !important;
  border: none !important;
}

.modal-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
  position: absolute;
  right: 5%;
  background-color: #fff;
  opacity: 1;
  top: 5%;
}

/* CSS */

.button-59 {
  margin-top: 5px;
  align-items: center;
  background-color: #fff;
  border: 2px solid #000;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  letter-spacing: -0.8px;
  line-height: 24px;
  min-width: 100%;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-59:focus {
  color: #171e29;
}

.button-59:hover {
  border-color: #06f;
  color: #06f;
  fill: #06f;
}

.button-59:active {
  border-color: #06f;
  color: #06f;
  fill: #06f;
}

.service-section {
  margin: 4rem 0;
  scroll-margin-top: 100px;
  /* Offset for smooth scrolling */

}

.service-content h3 {
  font-weight: bold;
  color: #1441a9;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.service-content p {
  color: #000000;
  font-size: 13px;
  margin-top: 45px;
}

.service-section hr {
  margin: 2rem 0;
}

.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.card-image {
  flex: 1;
  order: 2;
}

.card-image::before {
  content: "";
  width: 250px;
  height: 250px;
  border-radius: 132px;
  opacity: 0.4;
  background: linear-gradient(180deg, #7939cc 0%, #e26682 100%);
  position: absolute;
  right: 0;
}

.card-container.alternate .card-image {
  order: 1;
}

.card-container.alternate .card-image::before {
  content: "";
  width: 250px;
  height: 250px;
  background-color: #002bff;
  position: absolute;
  left: 0;
}

.card-content {
  flex: 1;
  order: 1;
}

.card-container.alternate .card-content {
  order: 1;
}

.about-top-part {
  color: #fff;
  margin-top: 3rem;
  text-align: center;
}

.about-top-part img {
  width: 85%;
}

.aboutus_section {
  padding-top: 7rem;
}

.aboutus_section h2 {

  font-size: 80px;
  text-transform: capitalize;
  /* font-family: sans-serif; */
  font-weight: bold;
}

.vission_section {

  position: relative;
  flex-wrap: nowrap;
  margin-top: 5rem;
}

.vission_section_part img {
  width: 100%;
}

.vission_section_part {
  position: relative;

}

.vission_section {

  background: url("/public//image/aboutus/vission.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;

}

.vission_section_who h2 {
  font-size: 55px;
  background: linear-gradient(91deg, #4656a3 0.62%, #7939cc 82.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
}


.vission_style .white_bg {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 40px;
  width: 90%;
  /* height: 130px; */
  display: flex;
  /* flex-direction: column; */
  /* text-align: center; */
  align-items: center;
}

.vission_style .white_bg p {
  margin: 0;
}

.vission_style {
  margin-bottom: 3rem;
}



.wrapper_vission .vission_count {
  background-color: #fff;
  border-radius: 50px;
  width: 70px;
  height: 70px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  /* flex-direction: column; */
  text-align: center;
  /* align-items: center; */
  position: absolute;
  margin-top: -30px;
  margin-left: -25px;
}

.wrapper_vission .vission_count p {
  font-size: 35px;
  background: linear-gradient(91deg, #4656a3 0.62%, #7939cc 82.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
  font-weight: bold;
}

.wrapper_vission .vission_style.alternate {
  margin-left: 100px !important;
}

.vission_section_who p {
  /* margin-right: 60px; */
  text-align: justify;
}

.wrapper_future {
  /* width: 80%;
  height: 300px; */
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

}

.future_section h1 {
  font-size: var(--main-card-heading-latop);
  margin-bottom: 20px;
}

.wrapper_future ul li {
  /* font-weight: bold; */
  margin-bottom: 20px;
  color: #4656a3;
  font-size: var(--main-paragragh-laptop);
}

.right_side_animation {
  position: absolute;

  right: 0;
}

.rocket {
  float: right;
  border-radius: 300px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      #ebf5ff 0%,
      rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(21px);
  padding: 18px;
  width: 200px;
  height: 200px;
}

.rocket img {
  transform: rotate(336deg);
}

.indiagate {
  width: 200px;
  height: 200px;
}

.service_perticular_content p {
  text-align: justify;
}

#Ecommerce,
#Document,
#B2C,
#Full_Truck,
#Part_Truck,
#Hire_fleet {
  width: 100%;
  position: relative;
  /* height: 100vh; */
}

.mobileApp {
  position: relative;

}

.mobileApp::after {
  content: "";
  position: absolute;
  background: url("../public/image/mobile/mobileApp.png");
  width: 690px;
  height: 100%;
  right: -3%;
  top: 14%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
}


@media (max-width: 767px) {
  .mobileApp::after {
    top: inherit;

    width: 100%;
    height: 35% !important;
    right: -3%;
    bottom: -5%;
    background-size: contain;
    z-index: 2;
  }

  .disabled_componets {
    display: block;
  }

  .home_slides .wrapper_slides .image_btn span {
    position: absolute;
    top: -15px;
    background: #fff;
    padding: 2% 1%;
    left: 0;
    width: 60%;
    margin: 0;
    border-radius: 50px;
    /* font-size: 15px; */
    text-align: center;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
    border: 3px solid #cbdee7;
    background: radial-gradient(231% 135.8% at 0.9% 100%,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(21px);
  }

  .home_slides .wrapper_slides:last-child .image_btn span {
    border: 3px solid #c6efdc;
  }

  .home_slides .wrapper_slides:nth-child(2) .image_btn span {
    border: 3px solid #c8b7eb
  }

  .home_slides .wrapper_slides:nth-child(3) .image_btn span {
    border: 3px solid #cacce5;
  }

  .wrapper_slides {
    text-align: center;
    margin: auto;
    position: relative;
  }

  .wrapper_slides img {
    width: 80% !important;

  }

  .wrapper_slides p {
    position: absolute;
    top: 10%;
    font-size: 15px;
    left: 0;
    right: 0;
    margin: auto;
    width: 60%;

  }

  .modal_discount_open p {

    font-size: 10px;
  }

  .home_slides .react-multi-carousel-list {

    overflow-x: clip !important;
    overflow-y: visible;

  }

  .trux-logo {
    width: 220px;
  }

  .off_canvas {
    display: block;

  }

  .home_slides {
    display: block;
    margin: 2rem 0;
  }

  .primum-steps {
    display: inherit;
    vertical-align: initial;
  }

  .card-container {
    display: inline;
  }

  .banner-title h2 {
    font-size: 20px !important;
    color: #fff;
  }

  .contact_section,
  .contact_signup,
  .media_section,
  .aboutus_section {
    height: 100% !important;
    background-size: cover !important;
  }

  .application_scan {
    height: 90vh !important;
  }

  .tracking_shipment .button-34 {
    /* padding: 5px; */
    font-size: 12px;
  }
}


.service-section-page {
  padding-bottom: 4rem;
  background: radial-gradient(328px at 2.9% 15%,
      rgb(191, 224, 251) 0%,
      rgb(232, 233, 251) 25.8%,
      rgb(252, 239, 250) 50.8%,
      rgb(234, 251, 251) 77.6%,
      rgb(240, 251, 244) 100.7%);
  -webkit-clipPath: polygon(0 0, 100% 0%, 96% 94%, 0% 100%);
  clip-Path: polygon(0 0, 100% 0%, 96% 94%, 0% 100%);
  background: radial-gradient(circle at 18.7% 37.8%,
      rgb(250, 250, 250) 0%,
      rgb(225, 234, 238) 90%);
}

.service-section-page p {
  font-size: 15px;
  margin-top: 1rem;
}

.service-section-page h4 {
  font-size: 50px;
  font-weight: bold;
  color: #7939cc;
  font-family: "Inter", sans-serif !important;
}

.service-section-page img {
  width: 95%;
}

.dropdown-item {
  line-height: 2.5;
  font-size: 15px;
  color: #000000 !important;

  background-color: #fff !important;
  padding: 0 !important;
}

.dropdown-item:hover {
  color: #7939cc !important;
}

.dropdown-item:focus {
  background: none !important;
}

.navbar-nav .dropdown-menu {
  padding: 0;
}

.form-contact div {
  margin: 20px 0;
}

/* ::placeholder {
  font-size: 13px;
} */

.form-contact {
  padding: 2rem;
  background: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


.form-contact .form-control {
  border-radius: 0;
}

.form-contact label {
  margin-bottom: 5px;
}

.form-contact h2,
.invoice-from h2 {
  font-size: 45px !important;

  text-transform: capitalize;
}

.accordion-button {
  color: #7939cc !important;
}

.accordion-item {
  border: none !important;
}

.accordion-header button {
  font-weight: 600 !important;
}

.accordion-button:not(.collapsed) {
  color: none !important;
  background-color: none !important;
  box-shadow: none !important;
}



.accordion-item {
  background-color: #fff !important;

  margin-bottom: 20px;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px 0px rgba(121, 57, 204, 0.25);
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 10px !important;
}

.faq-heading {
  text-align: center;
  margin-bottom: 2rem;
}

.faq-heading p {
  margin: 0;
}



.mazgine {
  margin: 5px;
}

.mazgine img {
  width: 100%;
  object-fit: cover;
  height: 400px;
}

.mazgine h6 {
  padding: 3px;
  font-size: 17px;
  margin-top: 2px;
}

.aboutpage-content {
  text-align: center;
  margin-top: 5rem;
}

.aboutpage-content.vission {
  color: #fff !important;
}

.aboutpage-content.vission ul li {
  line-height: 2.5;
}

.about_part_text p {
  font-size: 12px;
  margin-bottom: 10px;
}

.termcondition {
  border-radius: 32px;
  background: linear-gradient(278deg, #45108A 0%, #008 47.04%, #4656A3 100%);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);
  color: #fff;
  padding: 5rem;
  position: relative;
}

.information_security::before {
  content: "";
  background: url("/public/image/privacypolicy/5.png");
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  right: 0;
  width: 200px;
  height: 100%;
  top: 0;
}

.disclosure_information {
  position: relative;
}

.disclosure_information {
  width: 100%;
  background: url("/public/image/privacypolicy/6.png");

  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;

}

.applicationpPrivacypolicy,
.Useinformation {
  border-radius: 16px;
  opacity: 0.8;
  background: linear-gradient(278deg, #45108A 0%, #008 47.04%, #4656A3 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  padding: 5rem;
  position: relative;
}

.termcondition ol li,
.refund ol li,
.about_part_text ul li,
.applicationpPrivacypolicy ol,
.applicationpPrivacypolicy p,
.refund p,
.Useinformation ul,
.Useinformation p {
  font-size: var(--main-paragragh-laptop);
  margin-bottom: 20px;
  text-align: justify;
  font-weight: 300;
  padding: 0;
  /* text-transform: lowercase; */
}

.applicationpPrivacypolicy::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background: url("/public/image/privacypolicy/2.png");
  background-repeat: no-repeat;
  background-size: cover;

}

.Useinformation::before {
  content: '';
  position: absolute;
  top: 20%;

  right: 0;
  width: 220px;
  height: 500px;
  background: url("/public/image/privacypolicy/3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}


.two-fields-in-row {
  display: flex;
  justify-content: space-between;
}

.carrer-form {
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}


.carrer-form label {
  margin-bottom: 10px;
}


.btn-carrrer-submit {
  border-radius: 8px;
  border: 2px solid #FFF;
  background: linear-gradient(94deg, #008 1.92%, #7939CC 100%);
  padding: 10px 60px !important;
  color: #fff !important;
}

/* CSS */

.button-51 {
  background-color: transparent;
  border: 1px solid #266db6;
  box-sizing: border-box;
  color: #00132c;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 23px;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-51:hover,
.button-51:active {
  outline: 0;
}

.button-51:hover {
  background-color: transparent;
  cursor: pointer;
}

.button-51:before {
  background-color: #d5edf6;
  content: "";
  height: calc(100% + 3px);
  position: absolute;
  right: -7px;
  top: -9px;
  transition: background-color 300ms ease-in;
  width: 100%;
  z-index: -1;
}

.button-51:hover:before {
  background-color: #6dcff6;
}

/* .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
{
  padding: 3px 6px !important;
  border: none !important;
  color: #7939cc !important;
}
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  font-size: 1rem !important;
  color: #ffffff !important;
  background: linear-gradient(180deg, #7939CC 0%, #4656A3 100%) !important;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  border-radius: 2px;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  border: 1px solid #FFF !important;
  background: linear-gradient(97deg, rgba(121, 57, 204, 0.90) 0%, rgba(70, 86, 163, 0.90) 100%) !important;
  color: #fff !important;
} */
/* .tracking_shipment input.form-control {
  width: 100%;
  display: inline;
  border-radius: 5px;
  border-color: rgba(77, 95, 179, 1);
  padding: 2% 24% 2% 2%;
  border-radius: 50px;
  border-width: 2px;
  padding-left: 20px;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(21px);
} */
.tracking_shipment {
  /* position: relative; */
  width: 100%;
  DISPLAY: FLEX;
  JUSTIFY-CONTENT: SPACE-BETWEEN;
  /* gap: 50px; */
  margin-top: 2px;

}

.tracking-header img {
  background-color: #fff;
  width: 250px;
  padding: 10px;
  border-radius: 10px;
}

.wrapper_pinocode {
  border-radius: 20px;
  background: linear-gradient(0deg, #E9E3F5 0%, #E9E3F5 100%), #FFF;
  margin: 2rem 0;
  padding: 1.2rem 0;
}

.pincode_courrier_image {
  width: 45%;
}

.pinoce_flex_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pinoce_flex_wrapper h6 {

  margin: 0;
}

.pinoce_flex_wrapper p {
  padding: 0;
  font-weight: 600;
  color: #7939cc;
}

.pinoce_flex_wrapper span {
  font-size: 18px;
  vertical-align: middle;
  bottom: 0;
  font-weight: bold;
  color: #5b4ab4;
}

.pincodeNotFound {
  width: 40%;
}

.pincode_courrier_warraper,
.pincode_center,
.Serviceability_icon,
.oda_icon {
  width: 30%;
  text-align: center;
}

i.fa-solid.fa-check {
  color: green !important;
  margin: 0 !important;
  font-size: 25px !important;

}

i.fa-solid.fa-xmark {
  color: #ce0101;
  margin: 0;
  font-size: 25px;
}

.button-34 {

  border-radius: 50px;
  background: linear-gradient(89deg, #7939cc 2.76%, #008 95.54%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  /* box-sizing: border-box; */
  color: #ffffff;
  cursor: pointer;
  /* margin: 1%; */
  font-size: 16px;
  font-weight: 700;
  line-height: 23.6px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 2.2% 7%;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  border: 0;
  /* position: absolute;
  right: 0%;
  top: 0; */
}

.form-control:focus {
  border: 2px solid #5b42f3 !important;
  border-color: inherit !important;
  outline: 0;
  box-shadow: none !important;
}

.order-details {

  padding: 2rem;
  border-radius: 10px;

  border-radius: 32px;
  background: rgba(121, 57, 204, 0.05);
  box-shadow: 4px 4px 4px 0px rgba(121, 57, 204, 0.25);
}

.display_flex_details {
  display: flex;
  justify-content: space-between;

}

.display_flex_details p {
  margin-bottom: 25px;
}

/* Override the default style on pages where you want to show the scrollbar */
body.order-details ::-webkit-scrollbar {
  display: auto;
  /* or any other relevant value for showing the scrollbar */
}

.tracking-section {
  padding: 1rem 0;
  /* background: linear-gradient(271deg, #7939CC 0%, #4656A3 100.46%), #FFF; */
  background: linear-gradient(100deg, #7939CC 0.83%, #21599C 50.4%, #008 102.84%);
  box-shadow: 0px 4px 4px 0px rgba(121, 57, 204, 0.25), 0px 4px 4px 0px rgba(121, 57, 204, 0.24) inset;
}

.tracking_nav {
  position: relative;
}

.scan_tracking {
  width: 100%;
  height: 800px;
  overflow-y: scroll;
}

/* .order-details.tracking-orders {
  margin-top: -300px;
} */
.scan_details {
  margin-bottom: 40px;
  display: flex;
  /* gap: 40px; */
  justify-content: space-around;
}

.courier_tacking_image {
  width: 30%;
  height: 100%;
  /* margin-bottom: 20px; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.scan_tracking .status_scan {
  /* width: 30%; */
  font-size: 18px !important;

}


.tracking_date_time {

  color: #777777;
}

.order-details .master_no {
  font-weight: bold;
  font-size: 25px;
  margin: 10px;
  color: #5b42f3;
}

.order-details h6 {
  font-size: 15px;
  margin: 0;
  color: #7939cc;
  margin-top: 5px;
}

.order-details h5 {
  font-size: 17px;


}

.order-flex {
  display: flex;
  gap: 2px;
}

dt {
  font-weight: 600 !important;
  font-size: 12px !important;
}

dd {
  font-size: 13px !important;
}

.timeline-with-icons {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline-with-icons .timeline-item {
  position: relative;
  margin-bottom: 20px;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -48px;
  background-color: hsl(217, 88.2%, 90%);
  color: hsl(217, 88.8%, 35.1%);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-item p {
  font-size: 12px !important;
  margin: 0;
}

.button {
  padding: 10px 30px;
  color: #000;
  font-weight: 600;

  cursor: pointer;
  display: inline-block;
}

.button-3 {
  border: none;
  background: linear-gradient(97deg, #4656a3 1.25%, #7939cc 102.15%);
  border-radius: 5px;
  color: #fff;
  transition: .3s;
}

.button-3:hover {
  box-shadow: 0 0 0 1px #fff, 4px 4px #7939cc;
  transition: .3s;
}

.button-2 {
  border: 2px solid #4656a3;
  background: #fff;
  border-radius: 5px;
  color: #4656a3;
  ;
  transition: .3s;
}

.button-2:hover {
  box-shadow: 4px 4px #7939cc;
  transition: .3s;
}

.button-navigation-2 {
  border: 2px solid #ffffff;
  background: transparent;
  border-radius: 5px;
  color: #ffffff;
  ;
  transition: .3s;
}

.button-navigation-2:hover {
  box-shadow: 4px 4px #ffffff;
  transition: .3s;
}

.button-navigation-3 {
  border: none;
  background: #fff;
  border-radius: 5px;
  color: #7939cc;
  ;
  transition: .3s;
}

.button-navigation-3:hover {
  box-shadow: 0 0 0 1px #7939cc, 4px 4px #dbdbdb;
  transition: .3s;
}


.button-reg {
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(97deg, #4656a3 1.25%, #7939cc 102.15%);
  border: 0;
  /* border-radius: 8px; */
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  /* font-weight: 600 !important; */
  color: #ffffff !important;
  padding: 10px 25px !important;
  text-transform: capitalize;
  font-size: 15px !important;
}

.button-reg:active,
.button-reg:hover {
  outline: 0;
}

.navigation_Section .button-reg {
  align-items: center;
  border-radius: 4px;
  background: #fff;
  border: 0;

  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  font-weight: 600 !important;
  color: #7939cc !important;

}

.navigation_Section .button-reg:active,
.navigation_Section .button-reg:hover {
  outline: 0;
}

.login-section h5 {
  color: #0046a1;
}

.ndr-mangemnet-part h2,
.ndr-mangemnet-youtube h2 {
  color: #7939cc;
  font-size: 30px !important;
  margin: 2rem 0;
  text-transform: capitalize;
}

.ndr-mangemnet-youtube ul li {
  margin-bottom: 20px;
}

.ndr-mangemnet-youtube ul {
  margin: 0;
  padding: 0;
  text-align: justify;
}



.ndr-mangemnet-part h5 {
  font-size: 22px !important;
  font-weight: 400;
  text-transform: capitalize;
}

.ndr-mangemnet-part p {
  font-size: 15px;
  margin: 1rem 0;
  text-align: justify;
}

.ndr-mangemnet-part ul li {
  font-size: 15px;
  margin-bottom: 15px;
  text-align: justify;
  /* font-weight: 500 !important; */
}

.ndr-mangement-section {
  /* background: linear-gradient(to top, #fff1eb 0%, #9e8bff 100%); */
  background: linear-gradient(117deg, #92a2ef 0%, #d8baff 99.07%);
  border-radius: 35px;
  padding: 4rem;
}

.ndr-mangemnet-part {
  border-radius: 16px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(21px);
  padding: 2rem;
  position: relative;
  overflow: hidden;
  /* height: 100vh; */
  border: 2px solid #fff;
  font-size: var(--main-paragragh-laptop);
}

.stages_section ul li {
  margin-bottom: 15px;
}

.shiping-rate_cal {
  border-radius: 16px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(21px);
  padding: 2rem;
  position: relative;
  overflow: hidden;

  border: 2px solid #fff;
}

.shiping-rate_cal::before {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  left: -40px;
  bottom: -60px;
  background-image: url("/public/image/solution_folder/ratecalculatar/2.png");
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}

.shiping-rate_cal::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  left: -40px;
  top: 30%;
  background-image: url("/public/image/solution_folder/ratecalculatar/4.png");
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}

.ndr-mangemnet-youtube {
  border-radius: 16px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(21px);
  padding: 2rem;
  position: relative;
  overflow: hidden;
  font-size: var(--main-paragragh-laptop);
  border: 2px solid #fff;
}

.ndr-mangemnet-youtube::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  left: -40px;
  bottom: -60px;
  background-image: url("/public/image/solution_folder/weight/element1.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.ndr-mangemnet-youtube::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  right: 0;
  bottom: 0;
  background-image: url("/public/image/solution_folder/weight/element2.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.ndr_features_section {
  background-image: url("/public/image/solution_folder/ndr/6.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.ndr_features_section_picture {
  position: relative;
}

.ndr_features_section_picture img {
  /* position: absolute;
  right: -68px; */
  width: 100%;
}

.all_rounder_gradient_box {

  width: 60%;
  height: 220px;
  background: linear-gradient(265deg,
      #7939cc 1.91%,
      #6445bb 36.38%,
      #4656a3 85.41%);
  /* position: absolute; */
  display: inline-block;

  border-radius: 0 50px 0 0;
  z-index: -19;
  margin-left: -10%;
  margin-bottom: -10%;
}

.resons_ndr_card {
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 30px;
  width: 100%;
  height: 270px;
}

.resons_ndr_card img {
  width: 28%;
}

.resons_ndr_card h5 {
  font-size: 18px !important;
  font-weight: 500;
}

.resons_ndr_card p {
  font-size: 14px;
}

.resons_ndr_card img {
  margin-bottom: 10px;
  filter: brightness(1);
}

.resons_ndr_card:hover {
  border-radius: 24px;
  background: linear-gradient(137deg, #7939CC -1.04%, #4656A3 100%);
  color: #fff !important;
  border: 2px dashed #fff;

}


.reduce_ndr_section .ndr-mangemnet-part::after,
.ndr-mangemnet-youtube::after {

  content: "";
  width: 400px;
  height: 543px;
  border-radius: 543px;
  opacity: 0.5;
  background: #6b00f5;
  filter: blur(125px);
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;

}

.faq_section {
  padding: 2rem;
  border-radius: 16px;
  background: linear-gradient(278deg, #45108A 0%, #008 47.04%, #4656A3 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.faq_section.faq_collapse {
  width: 100%;
  height: 200px;

  overflow: hidden;

}

.faq_section.faq_collapse:hover {
  height: 100% !important;

}

.faq_section button.accordion-button {
  color: #008c !important;

}

.wrapper_faq_section {
  padding: 2rem;
  border-radius: 10px;
  border: 2px solid #fff;
}

.faq_section h3 {
  font-size: 30px;
  color: #fff;
  border-bottom: 3px solid #fff;
}

.button-add-item {
  background: linear-gradient(97deg, #4656a3 1.25%, #7939cc 102.15%) !important;
  color: #fff !important;
  font-size: 14px !important;

}

@media (min-width: 768px) {
  .button-52 {
    padding: 13px 50px 13px;
  }
}

.resons_ndr_card:hover img {
  filter: brightness(100);
}

.ndr_features_section h4 {
  font-size: 35px;
}

.ndr_features_section ol li {
  margin-bottom: 15px;
  font-size: 16px;
}

.why_ndr_Choose {
  background: #F0ECF9;
  padding: 4rem 0;
}

.why_ndr_Choose .main_difference {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.1) inset;
  border-radius: 16px;
  border: 3px solid rgba(255, 255, 255, 0);
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%);
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
  padding: 20px;
  border: 2px solid transparent;

}

.why_ndr_Choose .main_difference h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #7939cc;
}

.why_ndr_Choose .main_difference p::before {
  content: "\f101";
  font-family: FontAwesome;
  display: inline-block;
  width: 1.2em;
  color: #7939cc;
}

.weight-reconcillation p span {
  font-weight: 600;
  color: #000;
}

.weight img {
  clipPath: polygon(20% 0%,
      100% 0,
      100% 20%,
      100% 80%,
      100% 100%,
      20% 100%,
      0 100%,
      0% 20%);
}

.ndr-mangemnet-part h3 {
  font-size: 23px;
  font-weight: bold;
  text-transform: capitalize;
  color: #7841f7;
  margin: 2rem 0;
}

.ndr-mangemnet-part h4 {
  font-size: 20px;
}

.stages_section {
  text-align: justify;
  background: linear-gradient(0deg, #fff 1.08%, #cbd2ec 52.19%, #fff 100%);
}

.stages_images {
  text-align: end;
}

.stages_section h4 {
  font-size: 35px;
}

.stages_section h5 {
  font-size: 20px;
  text-transform: capitalize;
  color: #4656a3;
  font-weight: 600;
  margin-bottom: 2rem;
}

.stages_section h5::before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #4656a3;
  display: inline-block;
  margin-right: 20px;
}

.stages_section p {
  font-size: 15px;
}

.stages_section h2 {
  margin-bottom: 1rem;
  font-size: 15px;
  color: #4c54a8;
}

.stages_section img {
  width: 60%;
  max-height: 400px;
}

.section_tittle h4 {
  font-size: 18px;
}

.section_tittle p {
  font-size: 14px;
}

.inner-banner {
  position: relative;
  width: 100%;

  background: linear-gradient(112deg, #4656a3 0.8%, #7939cc 100%);
  padding: 7% 0%;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}



.inner-banner h6 {
  font-family: "Edu SA Beginner", cursive;

  position: absolute;
  top: 30%;
  left: 16%;
  width: 400px;
  font-size: 25px;
  color: #1441a9;
}

.cargo-stage img {
  margin-top: 9rem;
  width: 90%;
}

.tooltip-bottom {
  margin-left: 20px;
  position: relative;
  width: 200px;
  height: 80px;
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

.tooltip-bottom:after {
  content: "";
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #ffffff;
  height: 16px;
  width: 16px;
}

.road-border img {
  width: 100px;
}

.road-border {
  transition: all 0.5s ease-out;

  animation: s3-rotate 17s infinite linear;
  display: block;
  position: relative;
  top: 3px;
}

@keyframes s3-rotate {
  from {
    -webkit-transform: translateX(0%);
  }

  to {
    -webkit-transform: translateX(105%);
  }
}

.Integration-point {
  line-height: 2;
}

.Integration-content h6 {
  font-size: 20px;
  margin-bottom: 1rem;
}

.client-logo img {
  /* margin-top: 25px; */
  width: 100% !important;
  height: 110px;
  aspect-ratio: 3 / 3;
  object-fit: contain;
}

.logins-buttons img {
  width: 12px;
  margin-left: 5px;

  /* filter: invert(1); */
}

.scroltotop {
  border: 2px solid #fff !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  color: #fff !important;
  background: transparent !important;
}

.sort-select {
  float: right;
}

.blog-content h4 {
  font-size: 22px;
  font-weight: 600;

  cursor: pointer;
}

.blog_slider {
  display: none;
}

.blog-card {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  margin: 1rem 0;
  width: 100%;
  /* height: 450px; */
  position: relative;
}

.blog-card>.blog_main_image {
  border-radius: 10px 10px 0 0;
  clipPath: polygon(0 0, 100% 0, 100% 100%, 0 90%);
  width: 100%;
  /* height: 200px; */
  border-radius: 10px;
}

.blog-card>.blog_main_image img {
  border-radius: 10px;
}

.blog-content {
  padding: 40px 20px;
}

.blog-content h4 {
  font-size: 18px;
  font-weight: bold;
}

.blog-content .arrow-read {
  width: 12px;
  margin-left: 5px;
}

.read-blog {
  display: flex;
  gap: 25px;
}

.read-blog a:hover {
  color: #7939cc !important;
}

.blog-content small {
  color: grey;
}

.page-link {
  color: #000 !important;
}

.active>.page-link,
.page-link.active {
  background-color: #7939cc !important;
  border-color: #7939cc !important;
  color: #fff !important;
  border-radius: 50% !important;
}

.page-item:not(:first-child) .page-link {
  margin-left: 5px !important;
}

button.page-link.page-design {
  border-radius: 50%;
  margin: 0 5px;
  width: 40px;
  height: 40px;
}

.blog-ads-banner img {
  margin-top: 1rem;

  border-radius: 10px;
}

/* shimmer ui */
.card-shimmer {
  position: relative;
  -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 6px;
  height: 500px;
  overflow: hidden;
  width: 400px;
  margin: 40px auto;

  .shimmerBG {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 1200px 100%;
  }

  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }

    100% {
      background-position: 1200px 0;
    }
  }

  .media {
    height: 200px;
  }

  .p-32 {
    padding: 32px;
  }

  .title-line {
    height: 24px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 20px;
  }

  .content-line {
    height: 8px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  .end {
    width: 40%;
  }
}

.m-t-24 {
  margin-top: 24px;
}

/* shimmer ui */
.logo-partner-section {
  background: linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%);
  padding-top: 1rem;
  border-radius: 20px;
  margin-top: 3rem;
}

.order-status-parts {
  display: grid;
  /* justify-content: space-between; */
  gap: 30px;
  /* margin-top: 2rem; */
  grid-template-columns: repeat(5, 1fr);
  position: relative;
}

.order-status-parts .arrow_svg {
  width: 15%;
  height: 100%;
  position: relative;
  /* background-color: #7939cc; */

  /* padding:22px; */
  text-align: center;

}

.order-status-parts h6 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: #ffffff;
  margin: 20px 0 12px;
  font-weight: bold;
}

.order-status-parts p {
  color: #ffffff;
  font-size: 16px;
  height: 90px;

}

.order-status-parts img {
  width: 40px;
}

.order-status-parts .status_content::before {
  content: "";
  position: absolute;
  background: url("/public/image/process_icon/arrow.svg");
  width: 10%;
  height: 100%;
  top: 40%;

  /* left: 10%; */
  background-repeat: no-repeat;
  background-size: contain;
}

.order-status-parts .last_child::before {
  display: none;
}

.react-multiple-carousel__arrow--left {
  left: calc(45% + 1px) !important;
  /* display: none; */
}

.react-multiple-carousel__arrow {
  position: absolute;
  top: 80% !important;
  z-index: 2 !important;
  display: none;
}

.react-multiple-carousel__arrow--right {
  right: calc(48% + 1px) !important;
}

.react-multi-carousel-list {
  position: inherit !important;
}

.blog-card .blog-content p,
.blog-content .limit p span {
  font-size: 17px !important;
  text-align: start !important;
  /* margin: 20px 0; */
}

.blog-card .blog-content h3 {
  width: 100%;
  color: #0b0757;
  font-size: 18px;
  font-weight: bold;
  /* height: 75px; */
}

.blog-details img {
  width: 100%;
  margin: 20px 0;
}

.blog-details h2 {
  color: #7939cc;
  font-size: 40px;
  font-weight: 700;
  margin-top: 6rem;
}

.blog-details h2 strong {
  font-size: 25px !important;
}

.blog-details h3 {
  font-size: 20px !important;
}

.footer-section .lottie_animation {
  position: fixed;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.2;
}

.table {
  --bs-table-bg: none !important;
  text-align: center;
}

.button-20 {
  appearance: button;
  background-color: #4d4ae8;
  background-image: linear-gradient(180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0));
  border: 1px solid #4d4ae8;
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-20:focus:not(:focus-visible),
.button-20:focus {
  outline: 0;
}

.button-20:hover {
  background-color: #3733e5;
  border-color: #3733e5;
}

.button-20:focus {
  background-color: #413fc5;
  border-color: #3e3bba;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 0 1px 1px, rgba(104, 101, 235, 0.5) 0 0 0 0.2rem;
}

.button-20:active {
  background-color: #3e3bba;
  background-image: none;
  border-color: #3a38ae;
  box-shadow: rgba(46, 54, 80, 0.125) 0 3px 5px inset;
}

.button-20:active:focus {
  box-shadow: rgba(46, 54, 80, 0.125) 0 3px 5px inset,
    rgba(104, 101, 235, 0.5) 0 0 0 0.2rem;
}

.button-20:disabled {
  background-image: none;
  box-shadow: none;
  opacity: 0.65;
  pointer-events: none;
}

.slick-dots li button:before {
  color: white !important;
}

/* Define CSS classes for different shipment statuses */
.transit {
  background-color: #9d00ff;
  /* Blue color for "In transit" status */
  color: #fff;
  padding: 5px;
}

.manifested {
  background-color: #22bbff;
  /* Green color for "Manifiest" status */
  color: #fff;
  font-weight: 600;
  padding: 5px;

}

.pending {
  background-color: #f39c12;
  /* Orange color for "Pending" status */
  color: #fff;
  padding: 5px;

}

/* .undelivered {
  background-color: #ff5733;
  
  color: #fff;
} */

.notpicked {
  background-color: #e74c3c;
  /* Red color for "Undelivered" status */
  color: #fff;
  padding: 5px;

}


.dispatched {
  background-color: #8e44ad;
  font-weight: bold;
  color: #fff;
  padding: 5px;

}

table tbody td .delivered {
  background-color: #008000;
  padding: 5px;

  color: #fff;
}

.rto {
  background-color: #644117;
  /* Dark blue color for "Delivered" status */
  color: #fff;
  padding: 5px;

}

.pending {
  background-color: #e67e22;
  /* Orange color for "Pickup Reassigned" status */
  color: #fff;
  padding: 5px;

}

.tracking_icon {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  text-align: center;
  padding: 7px;
  font-size: 14px;
  position: relative;
  border: 2px solid #fff;


  /* margin-right: 20px; */
}

.tracking_icon i.fa-solid.fa-check {
  color: #fff !important;
  font-size: 15px !important;
}

.tracking_icon::before {
  content: "";
  width: 2.5px;
  height: 350px;
  position: absolute;
  background-color: #008000;
  left: 50%;
  right: 50%;
  margin: auto;
  z-index: -2;
}

.tracking_icon::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 15px solid #008000;
  border-radius: 2px;
  position: absolute;
  left: 28%;
  right: 50%;
  margin: auto;
  z-index: -2;
  bottom: -150%;
}

.tracking_action p {
  margin: 0;
  font-size: 15px;
}

.navigation_scan {
  display: flex;
  justify-content: space-between;
}

.breadcrumb {
  /*centering*/
  display: inline-block !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px;
  overflow: hidden;
  margin-top: 20px;
  border-radius: 5px !important;

  counter-reset: flag;
}

.breadcrumb a {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 17px;
  line-height: 36px;
  color: white;

  padding: 0 25px 0 30px;

  position: relative;
}

.breadcrumb a:first-child {
  border-radius: 5px 0 0 5px;
  /*to match with the parent's radius*/
}

.breadcrumb a:last-child {
  border-radius: 0 5px 5px 0;
  /*this was to prevent glitches on hover*/
  /* padding-right: 20px; */
  border-radius: 5px 5px 0 0;
}

/*adding the arrows for the breadcrumbs using rotated pseudo elements*/
.breadcrumb a:after {
  content: "";
  position: absolute;
  top: 0;
  right: -18px;
  width: 36px;
  height: 36px;

  transform: scale(0.707) rotate(45deg);

  z-index: 1;

  background: #666;
  background: linear-gradient(135deg, #666, #333);

  box-shadow: 2px -2px 0 2px rgba(0, 0, 0, 0.4),
    3px -3px 0 2px rgba(255, 255, 255, 0.1);

  border-radius: 0 5px 0 50px;
}

.breadcrumb a:last-child:after {
  content: none;
}

.flat a,
.flat a:after {
  background: #fff;
  color: black;
  transition: all 0.5s;
}

.flat a:before {
  background: white;
  box-shadow: 0 0 0 1px #ccc;
}

.flat a.activeManifested,
.flat a.activeManifested:after {
  background: #22bbff;
  color: #fff !important;
  /* font-size: 15px; */
}

.flat a.activeTransit,
.flat a.activeTransit:after {
  background: #7939CC;
  color: #fff !important;
  /* font-size: 15px; */
}

.flat a.activePending,
.flat a.activePending:after {
  background: #f39c12;
  color: #fff !important;
  /* font-size: 15px; */
}

.flat a.activeDispatched,
.flat a.activeDispatched:after {
  background: #8e44ad;
  color: #fff !important;
  /* font-size: 15px; */
}

.flat a.activeDelivered,
.flat a.activeDelivered:after {
  background-color: #008000;
  color: #fff !important;
  /* font-size: 15px; */
}

.flat a.activeRTO,
.flat a.activeRTO:after {
  background-color: #644117;
  color: #fff !important;
  /* font-size: 15px; */
}

.flat a.activeLost,
.flat a.activeLost:after {
  background-color: #ED1B24;
  color: #fff !important;
  /* font-size: 15px; */
}

.flat a.activenotpicked,
.flat a.activenotpicked:after {
  background-color: #e74c3c;
  color: #fff !important;
  /* font-size: 15px; */
}

.status_scan .Pending {
  background-color: #f39c12;
}

.signature {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.signature a {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #0cb9d8;
  text-decoration: none;
}

.home_slider .box-area {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.home_slider .box {
  width: 140px;
  height: 100%;
  margin-right: 20px;
  /* overflow: hidden; */
  border-radius: 20px;
  position: relative;
  transition: all linear 0.6s;
}

.home_slider .box:last-child {
  margin-right: 0;
}

.home_slider .box img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
}

.home_slider .box p {
  position: absolute;
  top: 10%;
  left: 15%;
  right: 15%;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
  color: #000000;
  opacity: 0;
  transition: all linear 0.3s;
}

.home_slider .box.active p {
  opacity: 1;
}

.home_slider .box .image_btn span {
  position: absolute;
  top: 50%;
  background: #fff;
  padding: 12% 10%;
  width: 240px;
  height: 50px;
  left: -80px;
  border-radius: 50px;

  font-weight: 600;
  margin: 0;
  font-size: 20px;
  transform: rotate(90deg);
  text-align: center;
  text-transform: capitalize;
}


.home_slider .box-area .active {
  /* width: 50%; */
  transition: all linear 0.6s;
}

.home_slider .box.active .image_btn span {
  position: absolute;
  top: -25px;
  background: #fff;
  padding: 2% 3%;
  width: 60%;
  /* height: 9%; */
  left: -60px;
  margin: 0;
  border-radius: 50px;
  /* font-size: 20px; */
  text-align: center;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
  text-transform: capitalize;
  border: 3px solid #cbdee7;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(21px);
  white-space: nowrap;
}

.box-area .box.active:last-child .image_btn span {
  border: 3px solid #c6efdc;
}

.box-area .box.active:nth-child(2) .image_btn span {
  border: 3px solid #c8b7eb
}

.box-area .box.active:nth-child(3) .image_btn span {
  border: 3px solid #cacce5;
}

.service_slider {
  background: linear-gradient(180deg, #6b80ce 0%, #7939cc 100%);
  padding: 3rem 0;
}

.service_slider .box-area {
  width: 100%;
  height: 100vh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.service_slider .boxes {
  width: 150px;
  height: 100%;
  margin-right: 20px;
  /* overflow: hidden; */
  border-radius: 20px;
  position: relative;
  transition: all linear 0.6s;
}

.service_slider .boxes:last-child {
  margin-right: 0;
}

.service_slider .boxes img {
  width: 100%;
  height: 80%;
  border-radius: 30px;
  object-fit: cover;
}

.service_slider .boxes p {
  position: absolute;
  top: 7%;
  left: 5%;
  /* right: 71%; */
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  opacity: 0;
  transition: all linear 0.3s;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.service_slider .box-area .active {
  width: 100%;
  transition: all linear 0.6s;
}

.service_slider h4 {
  color: #fff;
  font-size: 30px;
  text-transform: capitalize;
}

.service_slider p {
  color: #fff;
}



/* scan colors */

/* Define CSS classes for different shipment statuses */


.scan_details p {
  font-size: 15px;

}

.scan_details .Delivered,
.scan_details .delivered {
  color: #008000 !important;


}

.scan_details .Transit {
  color: #9d00ff !important;

}

.scan_details .Manifested {
  color: #22bbff !important;

}

.scan_details .Pending {
  color: #f39c12 !important;

}

.scan_details .RTO {
  color: #644117 !important;

}

.scan_details .LOST {
  color: #ED1B24 !important;

}

.scan_details .Dispatched {
  color: #8e44ad !important;

}

.scan_details .mismatched_status {
  color: #7939CC;

}

.scan_details .Not {
  color: #e74c3c;

}


/* scan colors */

.check_icon .mismatched_status {
  background-color: #7939CC;
  color: #fff !important;
}

.check_icon .Transit {
  background-color: #9d00ff;
  color: #fff !important;
}


.check_icon .Pending {
  background-color: #f39c12;
  color: #fff !important;
}

.check_icon .RTO {
  background-color: #644117;
  color: #fff !important;
}

.check_icon .LOST {
  background-color: #ED1B24;
  color: #fff !important;
}

.check_icon .Delivered {
  background-color: #008000;
  color: #fff !important;
}

.check_icon .Dispatched {
  background-color: #8e44ad;
  color: #fff !important;
}

.check_icon .Not {
  background-color: #e74c3c;
  color: #fff !important;
}

.check_icon .Manifested {
  background-color: #22bbff;
  color: #fff !important;
}



@media (max-width: 767px) {
  .inner-banner {
    padding: 3rem 0;
  }

  .inner-banner img {
    margin-top: 3rem;
  }

  .reduce_ndr_section .ndr-mangemnet-part::after,
  .ndr-mangemnet-youtube::after {

    right: 0;
    bottom: 0;
    top: auto;
  }

  .courier_tacking_image {
    width: 65%;
    height: max-content;

  }

  .breadcrumb {
    display: none !important;
  }

  .tracking_icon {

    position: absolute !important;
    left: 10%;
    /* top: 0; */
    /* bottom: 10px; */
  }

  .scan_tracking .status_scan {

    text-align: left;

  }

  .order-details {
    position: relative;
    margin-bottom: 2rem;
  }

  .scan_details {
    flex-wrap: wrap;
    gap: inherit;

  }

  .scan_tracking .status_scan {
    width: 100%;

  }

  .all_rounder_gradient_box {
    width: 60%;
    height: 100px;

  }

  .tracking_action {
    width: 100% !important;
  }

  .scan_tracking {

    padding: 2rem 2rem 2rem 6rem;
  }

  .ndr-mangemnet-part h2 {
    font-size: 30px !important;
  }

  .aboutpage-content h2 {
    font-weight: bold;
    font-size: 30px !important;
  }

  .aboutpage-heading p {
    height: 100%;
  }

  .missio_vission::before {
    display: none;
  }
}

.btn_rate_calculator {
  border-radius: 8px;
  background: linear-gradient(92deg, #4656A3 49.9%, #7939CC 102.93%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff !important;
  padding: 1% 10% !important;
  margin: 2rem 0;
}


.shiping_partner .rfm-child img {
  width: 95% !important;
  HEIGHT: 60px;
  aspect-ratio: 3 / 1;
  object-fit: CONTAIN;
  background: #fff;
  padding: 10px;
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}

.gradient_card {
  border-radius: 30px;
  background: linear-gradient(135deg, #c6acea 43.81%, #b7f6d0 90.36%);
  /* height: 550px; */
  width: 100%;
  padding: 2rem;
  margin: 1rem 0;
}

.benifits_customer .custom_gradient {
  border-radius: 30px;
  background: linear-gradient(135deg, #c6acea 43.81%, #8acaff 90.36%);
  /* height: 550px; */
  width: 100%;
  padding: 2rem;
  margin: 1rem 0;
}

.bg_benifits_card {
  border-radius: 25px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.63) 100%) !important;
  backdrop-filter: blur(21px) !important;
  padding: 1rem;
  width: 100%;
  /* height: 350px; */
}

.bg_benifits_card_content {
  border-radius: 25px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.63) 100%) !important;
  backdrop-filter: blur(21px) !important;
  padding: 1rem;
  margin-top: 10px;
  /* height: 125px; */
}

.bg_benifits_card h6 {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  color: #4656A3;

}

.bg_benifits_content p {
  color: #000000;
  font-size: 15px;
}

.integration {
  position: relative;
}

.integration::before {
  content: "";
  width: 400px;
  height: 543px;
  border-radius: 543px;
  opacity: 0.5;
  background: #4D5DA7;
  filter: blur(125px);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.integration_picture .integration_logo {
  display: flex;
  justify-content: space-between;
}

.integration_picture .integration_logo img {
  width: 20% !important;
}

.intrgation_gif {
  position: absolute;
  top: 55%;
  top: 60%;
  right: 0;
  left: 15%;
  width: 20%;
  margin: auto;
}

.btn-integration {
  padding: 13px 90px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  background: #6B80CE;
  color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 2rem;
}

.footer_term {
  text-align: center;
}

.footer_term .flag {
  width: 50px;
}

i.fa-solid.fa-heart {
  color: red !important;
}

.partner-trust {
  background: linear-gradient(98deg, #4656a3 1.98%, #7939cc 97.41%);

  padding: 2rem 0;
  clipPath: ellipse(70% 50% at 49% 50%);

  height: 100%;
}

.client-logo {
  background-image: linear-gradient(rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0));
  border-radius: 50%;
  width: 97%;

  height: 100%;
  padding: 10px;
  text-align: center;
}

.logo_bg {
  background: #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.circle-container {
  width: 200px;
  /* Adjust the container size as needed */
  height: 200px;
  /* Adjust the container size as needed */
  border-radius: 50%;
  /* Create a circular shape */
  overflow: hidden;
  /* Clip the content inside the circle */
  position: relative;
}

.circle-border {
  width: 100%;
  height: 100%;
  background: linear-gradient(179deg,
      rgba(246, 212, 64, 1),
      rgba(107, 128, 206, 1),
      rgba(121, 57, 204, 1),
      rgba(0, 198, 255, 1));
  border-radius: 50%;
  padding: 5px;
}

.newsletterwrapper {
  padding: 3rem;
  border-radius: 15px;

}

.newsletterinside {
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%);
  -webkit-backdrop-filter: blur(150px);
  backdrop-filter: blur(150px);
  /* height: 300px; */
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* height: 100vh; */
}

.news_letter_section h2 {
  font-size: 40px;
  text-transform: capitalize;
  background: linear-gradient(180deg, #233869 0%, #7939CC 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.news_letter_section h2 span {
  font-weight: bold;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.newsletterinside p {
  font-size: 20px;
}

.weight_newsletter .news_letter_section h2 {
  font-size: 40px;
  text-transform: capitalize;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.weight_newsletter .news_letter_section p {
  color: #fff;
}


.newsletter_input {
  position: relative;
  display: inline-block !important;
  width: 65% !important;
  height: 60px !important;
  padding: 10px 30px !important;
  border-radius: 10px !important;
  border: none !important;
  border-radius: 8px !important;
  background: #FFF !important;
  box-shadow: 0px 4px 4px 0px #36CB72;
}

.btn-light:hover {

  background-color: #ededed !important;

}

.btn_newletter {
  border-radius: 8px;
  background: linear-gradient(96deg, #7939CC 0%, #233869 50.73%, #6B80CE 97.42%);
  padding: 7px 30px !important;
  margin: 10px;
  border-radius: 5px;
  color: #fff !important;
  position: absolute;
  right: 21%;


}

.partner-trust h4,
.testimonials-section h4,
.shiping_partner h4 {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
}

.testimonials-section span {
  color: #fff;
}

.partner-trust h5 {
  font-size: 30px;
  font-weight: 700;
  background: radial-gradient(circle at 10% 20%,
      rgb(254, 255, 165) 0%,
      rgb(255, 232, 182) 90%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.services_page_bgtwo_section,
.services_page_bgone_section {
  position: absolute;
  top: 0;
  right: 0;
}

.main_picture img {
  width: 100%;
  z-index: 2;
  position: relative;
}

.btn_service_page {
  padding: 10px 50px;
  font-size: 17px;

  background: #4656a3;
}

.mobile_service_all_list {
  display: none;
}

.all_services_card {
  text-align: center;
  position: relative;
  /* background: #fff; */
  padding: 20px 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.all_services_card:hover {
  border-radius: 32px;
  background: linear-gradient(311deg,
      #d2576c -1.74%,
      #4656a3 50.88%,
      #4656a3 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  transform: scale(1.2);
  z-index: 3;
}

.all_services_card img {
  width: 60px;
  height: 60px;
  filter: brightness(1);
}

.all_services_card:hover img {
  filter: brightness(10);
}

.all_services {

  background: linear-gradient(170deg, #4f62b8 0%, #f5d9a9 47.96%, #fed7cd 100%);
  border-radius: 10px;
  padding: 3px;
}

.all_serives_white {
  background-color: #fff;

  border-radius: 8px;
  padding: 1rem;
  position: relative;
}

.all_serives_white .col-md-2 {
  position: relative;
}

.all_serives_white .col-md-2::after {
  content: "";
  position: absolute;
  border-radius: 8px;
  background: linear-gradient(180deg, #4656a3 0%, #f5d9a9 47.96%, #fed7cd 100%);
  height: 80px;
  width: 4px;
  top: 35%;
  left: 0;
  z-index: 1;
  background-repeat: no-repeat;
}

.all_services .col-md-2:first-child:after {
  display: none;
}

.service_top_content h4 {
  font-size: 35px;
  color: #4656a3;
}

.service_top_content p {
  margin-bottom: 50px;
  /* font-size: var(--main-paragragh-laptop); */
}

.service_top_content h5 {
  font-size: var(--main-card-heading-latop);
}

.service_perticular_content ul {
  text-align: justify;
  font-size: var(--main-paragragh-laptop);

}

.inner-banner .service_top_content h4,
.contact_section h4,
.media_section h4 {
  font-size: 40px;
  color: #ffffff;
  text-transform: capitalize;
}

.all_services h5 {
  font-size: 17px;
  margin-top: 10px;
}

.all_services p {
  font-size: 13px;
}

.hello_services {
  display: flex;
  justify-content: space-between;
  /* margin: 5rem 0; */
}

.service_perticular_image .ecomm1 {
  position: absolute;
  width: 100%;
  /* top: 78px; */
  right: -70px;
}


.service_perticular_image.parttruck_section {
  width: 100%;
  /* float: right; */
  text-align: center;
  position: relative;
}

.service_perticular_image {
  position: relative;
  text-align: center;
}

#Part_Truck {
  overflow: hidden;
}

.service-section h2 {
  background: linear-gradient(273deg, #7939cc 4.61%, #dd607e 102.15%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
}

.service-section h2 span {
  font-size: 80px;
}

.dextop_service_heading {
  display: none;
}

.document {
  position: absolute;
  width: 95%;
  top: 0;
  left: -70px;
}

.air_fright {
  /* position: absolute; */
  width: 80%;

  margin-top: 300px;
}



.parttruck,
.FullTruck,
.hirefleet {
  /* position: absolute; */
  width: 70%;
  margin-top: 5rem;
  /* margin-top: 5rem; */
}

.shiping_now {
  text-align: center;
  position: relative;
  color: #fff;
}

.shiping_now_inside {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 400px;
}

.shiping_now_inside_about {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 350px;
}

.shiping_now_inside_bussiness {
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: #fff;
  padding: 9rem 0;
}

.shiping_now_inside_bussiness h4 {
  font-size: 45px;
}


.ndr-mangemnet-part_faq {
  border-radius: 16px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%);
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
  padding: 3rem;
  border: 1px solid #fff;
}

.form_calcutor {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
  flex-wrap: nowrap;
}

.calculator_section {
  border-radius: 16px;
  padding: 2rem;
  background: linear-gradient(to top, #fff1eb 0%, #9e8bff 100%);
}

.calculator_section form {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0);
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(21px);
  border: 1px solid #fff;
  padding: 2rem;
}

.was-validated .form-control:valid {
  background-image: none !important;
  padding: 5px !important;
}

.calculator_section .form_calcutor input {
  line-height: 2 !important;
}

.button-reset {
  padding: 8px 40px !important;
  border: 2px solid #7939cc !important;
  width: 50%;
  color: #000000 !important;
}

.btn-outline-primary:hover {

  background-color: #ffffff !important;

}

.calculator_section label {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #7939CC;
}

/* .integration_picture_magento ul,
.integration_picture_magento ul {
  padding: 0;
} */

.integration_picture_magento ul li {
  margin-bottom: 10px;
}

.dimension_part {
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #fff;
}

.cal_buttons {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  flex-wrap: nowrap;

}

.dimension_part img {
  width: 60px;
  /* margin: 10px; */
}

.button-cal {
  border-radius: 8px;
  background: linear-gradient(96deg, #4656a3 64.1%, #7939cc 100%);
  padding: 10px 35px !important;
  font-size: 16px !important;
  color: #fff !important;
  width: 50%;
}

.dimension-center {
  position: relative;
  margin: 20px 0;
}

.userInputLength {
  position: absolute;
  left: 38%;
  top: 82%;
  transform: rotate(27deg);
}

.userInputWidth {
  position: absolute;
  right: 37%;
  top: 80%;
  transform: rotate(333deg);
}

.userInputHeight {
  position: absolute;
  right: 30%;
  top: 35%;
}

.form-select:focus {


  box-shadow: none !important;
}

.calculator_section .form-select {
  line-height: 2;
  /* color: #7f8184; */
}

.calculator_section .form-select .options {
  padding: 100px;
}

.home_slider .box-area_conversion {
  width: 100%;
  height: 80vh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.home_slider .box_conversion {
  width: 120px;
  height: 100%;

  position: relative;
  transition: all linear 0.6s;
}

.home_slider .box_conversion:last-child {
  margin-right: 0;
}

.home_slider .box_conversion .wrapper_conversion {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  background-color: #ffffff;
  padding: 2rem;
  overflow: hidden;
  text-align: center;
}

.home_slider .box_conversion .wrapper_conversion img {
  width: 300px;
  height: auto;
}

.home_slider .box_conversion p {
  position: absolute;
  /* top: 10%; */
  left: 15%;
  right: 15%;
  font-size: 15px;
  font-weight: 400;
  color: #000000;
  opacity: 0;
  transition: all linear 0.3s;
}

.home_slider .box_conversion.active p {
  opacity: 1;
}

.home_slider .box_conversion .image_btn {
  position: absolute;
  top: 50%;
  /* background: #fff; */
  padding: 13px 24px;
  width: 200px;
  height: 50px;
  left: -60px;
  margin: 0;
  border-radius: 50px;
  font-size: 20px;
  transform: rotate(90deg);
  text-align: center;
}

.home_slider .box-area_conversion .active {
  width: 900px;
  transition: all linear 0.6s;
}

.home_slider .box_conversion.active .image_btn {
  position: absolute;
  top: 0;
  /* background: #fff; */
  padding: 10px 24px;
  width: 200px;
  height: 50px;
  left: 0;
  margin: 0;
  border-radius: 50px;
  font-size: 20px;
  text-align: center;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
  /* Add transition property */
}

.conversion_section .Power {
  background: #d3f4fd;
  padding: 4rem;
}

.conversion_section .scan {
  background: #e3ddf8;
  padding: 4rem;
}

.conversion_section .email {
  background: #e6f2fe;
  padding: 4rem;
}

.conversion_section .truck {
  background: #f7eff6;
  padding: 4rem;
}

.conversion_section .rating {
  background: #d80544;
  padding: 4rem;
}

.conversionslider .wrapper_conversion {
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  width: 100%;
  /* height: 60%; */
}

.conversion_content {
  margin-top: 30px;
  font-size: 15px;
}

.conversion_content h6 {
  font-size: 25px;
  text-transform: capitalize;
  margin-top: 10px;
  font-weight: bold;
  color: #7939cc;
}

.conversionslider .wrapper_conversion img {
  width: 100%;
  height: 230px;
}

.box_conversion_slider {
  width: 100%;

}

.conversionslider .Power {
  background: #d3f4fd;
  padding: 2rem;
  height: 100%;
}

.conversionslider .scan {
  background: #e6f2fe;
  padding: 2rem;
  height: 100%;
}

.conversionslider .email {
  background: #d9cfd2;
  padding: 2rem;
  height: 100%;
}

.conversionslider .truck {
  background: #f7eff6;
  padding: 2rem;
  height: 100%;
}



.box_conversion_slider.rating {
  background: #e3ddf8;
  padding: 2rem;
  height: 100%;
}

.herocard-section {
  background: #B5BCE8;
  padding: 3rem 0;
  height: 100%;
}

.invisble-choose_wrapper {
  display: none;
}

.herocard-section .choose_wrapper {
  background-color: #fff;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 30px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 100%;
  /* height: 360px; */
}

.herocard-section .choose_wrapper svg {
  width: 150px;
  margin-bottom: 20px;
  height: 150px;
  object-fit: contain;
}

.herocard-section .choose_wrapper h5 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #4656A3;


}

.herocard-section .choose_wrapper p {
  color: #000000;
  font-size: 15px;

}

.services_componets {
  background: rgba(229, 233, 255, 0.4);
  padding: 3rem 0;

}

.services_componets .cards_services {
  padding: 35px;
  border-radius: 32px;
  background: linear-gradient(146deg, #21599c 2.59%, #6b80ce 100%);
  margin-bottom: 25px;
  width: 100%;
  /* height: 100%; */
}

.services_componets .cards_services .cards_glass {
  border-radius: 24px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.34) 15.06%,
      rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(21px);
  padding: 25px 0;
  width: 100%;
  /* height: 100%; */
}

.cards_glass h5 {
  margin-left: 30px;
  color: #fff;
  font-size: 25px;
  text-transform: capitalize;
  margin-bottom: 0;
  font-weight: bold;
}

.glass_content {
  background-color: #fff;
  padding: 20px;
  margin: 0 20px;
  border-radius: 20px;
  /* text-align: justify; */
  /* height: 140px; */
  font-size: 15px;

}

.glass_content p {
  margin: 0;
}

.shiping_partner h4 {
  margin-bottom: 2rem;
}

.nav_reg {
  display: none;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("/public/image/menu.svg") !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}

.navbarSection .outline-login {
  border: 2px solid #4656a3 !important;
  font-weight: 600 !important;

  color: #4656a3 !important;
  background-color: #fff !important;
  border-radius: 4px;
  padding: 8px 25px !important;
  cursor: pointer !important;
  position: relative;

}

.navbarSection .outline-login:hover:after {
  background-color: transparent !important;
  border-radius: .25rem !important;
  content: "" !important;
  display: block !important;
  height: 36px !important;
  border: 2px solid #4656a3 !important;
  left: 0 !important;
  width: 100% !important;
  position: absolute !important;
  top: 0 !important;
  transform: translate(7px, 11px) !important;
  transition: transform 0.2s ease-out !important;
  z-index: -1 !important;
}

.navbarSection .button-reg:hover:after {
  background-color: transparent;
  border-radius: 0.25rem;
  content: "";
  display: block;
  height: 38px;
  border: 2px solid #4656a3;
  /* left: 0; */
  width: 57%;
  position: absolute;
  top: -2px;
  transform: translate(-14px, 13px);
  transition: transform 0.2s ease-out;
  z-index: -1;
}




.navigation_Section Nav .nav-link {
  white-space: nowrap;

  color: #ffffff !important;


}

.navigation_Section Nav .trux-logo {
  filter: invert();
}

.navigation_Section .button-reg:hover:after {
  background-color: transparent;
  border-radius: 0.25rem;
  content: "";
  display: block;
  height: 38px;
  border: 2px solid #ffffff;
  /* left: 0; */
  width: 59%;
  position: absolute;
  top: -2px;
  transform: translate(-14px, 13px);
  transition: transform 0.2s ease-out;
  z-index: -1;
}

.navigation_Section .sticky {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: linear-gradient(274deg, #7939cc 2.76%, #4656a3 95.54%);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  filter: invert(0);
}

.accordion-button:not(.collapsed) {
  background-color: #ffffff !important;

}

.accordion-header {
  margin-bottom: 0 !important;
}

button.accordion-button {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.accordion .accordion-item .accordion-body {
  padding: 0 20px 20px;
}

.navigation_Section {
  background-color: transparent;
}

.navbar-toggler {
  background-color: #fff !important;
}

/* .integration_picture_magento ul,
.rate-content ul {
  padding: 0;
} */

.integration_picture_magento ul li {
  margin-bottom: 10px;
}

.superiority {
  text-align: center;
}

.superiority img {
  width: 100%;
}

.offcanvas-body .navbar-nav .nav-link {

  border-bottom: 0.8px solid grey;
  position: relative;

}

#offcanvasNavbarDropdown-expand-false::after {
  display: none;
}

.offcanvas-body .solution_image img {
  border-radius: 0;
}

/* .offcanvas-body .navbar-nav .nav-link::before{

  content: ">";
  font-family: 'Inter';
  font-size: 17px;

  display: inline;
  position: absolute;
  right: 0;

} */
.offcanvas-body .b {
  border-bottom: none !important;
}

.dash-gradient {
  background: linear-gradient(90deg, #7939CC 0%, #10A81B 48.91%, #008 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 767px) {
  .services_componets .cards_services {

    margin-bottom: 0;

  }

  .logo_bg {

    padding: 8px;
  }

  .btn-outline-reset {
    width: 100% !important;
    margin: 10px 0 !important;
  }

  .button-add-item {
    font-size: 14px !important;
  }

  .rate_cal_stages {
    padding: 2rem !important;
  }

  .Integration_steps {
    padding: 1.5rem !important;
  }

  .Integration_steps::before,
  .Integration_steps::after {
    display: none;
  }

  .filter_blogs .form-select {
    margin-top: 10px;
    width: 100%;
  }

  .logins-buttons {
    display: inherit;
    border-top: 1px dashed #7939CC;
    padding: 10px 0;
    border-bottom: 1px dashed #7939CC;
  }

  .outline-login {
    width: 100%;
    margin-top: 1rem;
    background: #fff !important;
    border: 2px solid #000;
    padding: 10px 15px;
    border-radius: 4px;
    /* box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px; */
  }

  .button-cal,
  .button-reset {
    width: 100%;
    /* padding: 7px 18px !important; */

  }

  Nav .nav-link {
    line-height: 2;
    border-bottom: 1px solid;
    margin-right: 0;
  }

  .navbar-nav .dropdown-menu {
    padding: 20px;
    width: 100%;
  }

  .solution_body,
  .resource_body {
    width: inherit;
    padding: 0;
    box-shadow: none;
  }

  /* .solution_image {
    display: none;
  } */

  .solution_items,
  .resource_items {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 20px;
  }

  .button-reg {
    align-items: center;
    border-radius: 4px;
    background: linear-gradient(97deg, #4656a3 1.25%, #7939cc 102.15%);
    border: 0;
    /* border-radius: 8px; */
    /* box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px; */
    /* font-weight: 600 !important; */
    color: #ffffff !important;
    padding: 12px 16px !important;
    font-size: 15px !important;
    width: 100%;
    margin-top: 10px;
  }

  .button-reg:active,
  .button-reg:hover {
    outline: 0;
    background: black !important;
    /* border: 2px solid #fff; */
  }



  .navbarSection,
  .navigation_Section {
    position: relative !important;
    top: 0;
    width: 100%;
  }

  /* .button-34{
    position: relative;
    margin: 10px 0;
    width: 100%;
  } */
  .tracking_shipment {
    text-align: center;
  }

  .tracking_shipment ::placeholder {
    font-size: 14px;
  }

  /* .button-34 {
    padding: 6px 25px;
    margin: 3px;

  } */

  .HeroSpecification-section .hero-points-part .hero-points {
    text-align: center;
    font-size: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 5px;
    background: #fff;
    padding: 10px;
    margin: 5px;
    width: 96px;
    height: 96px;
    font-weight: 500;
    flex-wrap: wrap;
  }

  .home_slider .box img {
    width: 100%;
    height: 50vh;
    border-radius: 10px;
    object-fit: cover;
  }

  .home_slider .box .image_btn {
    position: absolute;
    top: 24%;
    background: #fff;
    padding: 8px 19px;
    width: 163px;
    height: 35px;
    left: -59px;
    margin: 0;
    border-radius: 50px;
    font-size: 14px;
    transform: rotate(90deg);
    text-align: center;
  }

  .home_slider .box.active .image_btn {
    position: absolute;
    top: -25px;
    background: #fff;
    padding: 10px;
    width: 124px;
    height: 40px;
    font-size: 12px;
    left: -7px;
    transition: transform 0.3s ease;
    /* Add transition property */
    background: radial-gradient(231% 135.8% at 0.9% 2.98%,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(21px);
  }

  .home_slider .box {
    margin-right: 5px;
  }

  .home_slider .box p {
    position: absolute;
    top: 5%;
    left: 15%;
    right: 15%;
    font-size: 8px;
    font-weight: 400;
    color: #000000;
    opacity: 0;
    transition: all linear 0.3s;
  }

  .track-shipment {
    margin-top: 1rem;
    /* border-radius: 15px; */
  }

  .nav-tabs .nav-link {
    font-size: 12px;
  }

  .bbb-wrapper .subcribe-form #subscribe #subscribe-button {
    height: 22px;
    padding: 0px 12px;
    border-radius: 3px;
    border: none;
    position: absolute;
    right: 6px;
    top: 4px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
  }

  .bbb-wrapper .subcribe-form #subscribe input {
    height: 30px;
  }

  ::placeholder {
    /* font-size: 12px; */
    margin-left: 20px !important;

  }

  .about-section {
    padding: 20px;
  }

  .logo-partner-section {
    background: linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%);
    padding: 1rem 0;
  }

  .shiping_partner {
    height: 100% !important;
    padding: 2rem 0px !important;
  }

  .shiping_partner li {
    padding: 10px;
    /* height: 55px !important; */
  }

  .about-bg p {
    font-size: 11px;
  }

  .about-bg h5,
  .logo-partner-section h5,
  .testimonial-heading h5,
  .herocard-section h5,
  .rate-calculator h5,
  .service-section h5,
  .invoice-section h5,
  .integration h5,
  .partner-trust h5 {
    font-size: 20px;
  }

  .HeroSpecification-section .hero-points-part .hero-points {
    text-align: center;
    font-size: 14px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 5px;
    background: #fff;
    padding: 12px;
    margin: 8px;
    flex-basis: 45%;
    font-weight: 500;
  }

  .about-bg h1 {
    font-size: 40px;
  }

  .icons-number img {
    width: 20px;
    height: 20px;
  }

  .triangle-top-left {
    border-top: 55px solid #1441a9;
    border-right: 60px solid transparent;
  }

  .working-featured span {
    font-size: 35px;
  }

  .working-featured h3 {
    font-size: 20px;
  }

  .working-featured p {
    font-size: var(--main-paragragh--mobile);
  }

  .order-status-parts p {
    color: #ffffff;


    font-size: 11px;
    height: 100%;
    display: none;
  }


  .cards_glass h5,
  .bg_benifits_card h6,
  .conversion_content h6,
  .working-featured h3 {
    font-size: var(--main-card-heading-mobile);
  }

  .custom-svg,
  .custom-svg-two {
    padding: 20px 10px !important;
    background-size: contain !important;
  }

  /* .status_content {
 
  padding: 0 30px;
} */
  .order-status-parts .status_content::before {
    top: 100%;
    left: -20%;
    transform: rotate(90deg);
    /* position: relative; */
    width: 5%;
    margin-left: 50%;
    margin-right: 50%;
    margin: auto;
    text-align: center;
    align-items: center;
    /* display: block; */
    right: 0;
  }



  .logo-partner img {
    width: 65px;
    transition: transform 0.3s ease-in-out;
    height: 65px;
  }

  .rate-content h6 {
    margin-top: 2rem;
    font-size: 22px;
  }

  .rate-content p {
    font-size: 12px;
  }

  .orderfirst {
    order: 2;
  }

  .ordersecond {
    order: 1;
  }

  .grow ul li {
    line-height: 2;
    font-weight: 600;
    font-size: 12px;
  }

  .testimonials-section {
    padding: 20px 0;
  }

  .testimonal-card {
    padding: 20px !important;
    height: 300px;
    /* background-size: contain !important; */
  }

  .testimonal-card small {
    font-size: 12px;
  }

  .testimonials-section {
    /* clipPath: inset(0 -4% 0 4%); */
    position: relative;
  }

  .review_quotes img {
    margin-bottom: 20px;
  }

  .testimonials-section .quotes {
    top: 0;
    position: relative;
    left: 0;
  }

  .testimonal-card img {
    width: 80px;
    height: 80px;
  }

  .tes-content p {
    font-size: var(--main-paragragh--mobile);
  }

  .man-box {
    width: 70%;
    position: relative;
    top: 109px;
    /* left: 15%; */
  }

  .newsletter {
    padding: 1rem;
  }

  .footer-logo {
    width: 60%;
  }

  /* .hero-banner {
    width: 100%;
    height: 50vh;
  } */

  .video-overlay {
    background: linear-gradient(-225deg,
        rgba(172, 50, 228, 0.7) 0%,
        rgba(121, 24, 242, 0.7) 48%,
        rgba(72, 1, 255, 0.7) 100%);
  }

  .bg-man {
    margin-top: 0;
  }

  .dis {
    display: none;
  }

  .button-56 {
    padding: 0 40px;
  }

  .logins-buttons {
    justify-content: start;
  }

  .partner-trust h4,
  .testimonials-section h4,
  .shiping_partner h4 {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
  }

  .dashboard_section h4 {
    font-size: 20px;
    font-weight: bold;
  }

  .shiping_partner h4 {
    margin-bottom: 1rem;
  }

  .home_slider .box-area {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .process-section .client,
  .process-section .Orders,
  .process-section .Experts,
  .process-section .Deliveries,
  .process-section .Integrations,
  .process-section .pincodes {
    width: 45%;
    /* height: 150px; */
    border-radius: 20px;
    /* padding: 20px; */
    margin: auto;
    margin-bottom: 20px;

    border-radius: 20px;
    padding: 20px;
  }

  .margin_top_bottom {
    margin-top: 2rem;
  }

  #hide_components {
    display: none;
  }

  .working-featured h3:after {

    width: 80%;
    left: 0;
    right: 0;
    bottom: -20px;
    left: 0;
    top: inherit;
  }

  .order-status-section h4,
  .process-section h4,
  .blog-section h4,
  .rate-calculator2 h4,
  .benifits_customer h4,
  .herocard-section h4,
  .services_componets h4,
  .conversionslider h4,
  .integration h4,
  .dashboard_section h4 {
    font-size: 26px;
    font-weight: bold;
    color: #0b0757;
    margin-bottom: 2rem;
  }

  .dashboard_section {
    /* height: initial !important; */
    padding: 3rem 1rem !important;

    height: 55vh !important;
    background: url("/public/image/dash.png") #fff !important;
    width: 100%;

    background-position: bottom;
    background-size: contain !important;
    background-repeat: no-repeat !important;

    background-position: bottom !important;
  }

  .integration .integration_picture h4 {
    display: none !important;
  }

  .dashboard_section p {
    font-size: 14px;
  }

  .rate-content ul li,
  .rate-content p,
  .integration_picture_magento {
    font-size: var(--main-paragragh--mobile);
    text-align: justify;
  }

  .rate-calculator2 {
    height: auto !important;
    background-position: left !important;
  }

  .mobileApp {
    height: 650px !important;
  }

  .intrgation_gif {
    position: absolute;
    top: 25%;
    left: -20%;

    width: 40%;
  }

  .btn_rate_calculator {
    margin-top: 0;
  }

  .integration::before {

    top: 0;

  }

  .calculator_section,
  .calculator_section form {
    padding: 10px;
    margin-top: 2rem;

    background-position: right !important;

  }

  .calculator_result {
    margin-top: 2rem !important;
  }


  .bg_benifits_content p,
  .conversion_content p {
    color: #000000;
    font-size: var(--main-paragragh--mobile);
  }


  .gradient_card,
  .custom_gradient {
    height: inherit !important;
    width: 100%;
    padding: 1rem !important;
  }

  .bg_benifits_card {
    height: inherit;
  }

  .herocard-section .choose_wrapper svg {
    width: 90%;
    height: auto;

  }

  .herocard-section .choose_wrapper h5 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .herocard-section .choose_wrapper p {
    /* font-size: var(--main-paragragh--mobile); */
    font-size: 12px;
  }


  .footer-section .monuments_footer h2 {
    font-size: 18px;
    left: 0;
    right: 0;
    top: 14px;
  }

  .herocard-section .choose_wrapper {
    padding: 8px;


  }

  .btn-outline-light {
    width: 100%;
  }

  .partner-trust {
    padding: 2rem 0;
  }

  .partner-trust {
    height: inherit;
  }

  .react-multiple-carousel__arrow--right {
    right: calc(48% + 1px) !important;
    display: none;
  }

  .react-multiple-carousel__arrow--left {
    left: calc(53% + 1px) !important;
    display: none;
  }

  .blog-section .blog-content h4 {
    font-size: 18px;
  }

  .blog-card .blog-content p,
  .blog-content .limit p span {
    font-size: 15px !important;
  }

  .blog_slider {
    display: none;
  }

  .newsletterwrapper {
    padding: 1rem;
  }

  .news_letter_section h2 {
    font-size: 25px !important;
  }

  .newsletterwrapper {
    /* padding: 7rem 2rem; */
    margin-bottom: 2rem;
    background-position: left !important;
  }

  .newsletterinside p {
    font-size: 14px;
  }

  button.btn.btn_newletter {
    padding: 7px 10px;
  }

  .faq_section h3 {
    font-size: 18px;

  }

  .newsletterinside {
    height: inherit;
    padding: 50px 10px;
  }


  .newsletter_input {
    width: 100% !important;
    height: 50px !important;
    padding: 10px 13px !important;
    border-radius: 4px;

  }

  .weight-stage_content h5 {
    font-size: 25px !important;

  }

  .btn_newletter {

    bottom: 0;
    right: 0;
    left: 0;
    /* width: 100%; */

  }

  .hero-banner {
    padding-top: 1rem !important;
    background-position: right !important;
    background-size: cover !important;
    /* background: none !important; */
    /* height: inherit !important; */
  }

  /* .hero-banner::before {
    content: "";
    background: url("/public/image/mobile-26.png");
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 350px;
    z-index: -1;
  } */

  /* .hero-banner::after {
    content: "";
    background: url("/public/image/home_banner_pattern_full_2.png");
    background-repeat: no-repeat;
    position: absolute;
    top: -10%;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5;
  } */

  .home_slider {
    display: none;
  }

  .nav_reg {
    display: block;
  }

  .order-status-parts {
    /* gap: 45px; */
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
  }

  .order-status-parts .arrow_svg {
    width: 65%;
    height: 100%;
    margin: auto;

  }

  .order-status-parts .arrow_svg::before {
    left: -50px;
  }

  .order-status-parts .arrow_svg:last-child::before {
    display: block;
  }

  .cards_services .cards_glass p {
    font-size: var(--main-paragragh--mobile);
  }

  .services_componets .cards_services {
    padding: 15px;
  }

  div#navbarScroll {
    padding: 10px;
  }



  .testimonials-section .btn-outline-light {
    padding: 10px 30px !important;
  }

  .hero-banner h1 {
    font-size: 30px;

  }

  /* ::placeholder{
  font-size: 10px;
} */

}

/* responive service page start */
@media (max-width: 767px) {

  #Ecommerce .row,
  #B2C .row,
  #Part_Truck .row {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  #Ecommerce,
  #Document,
  #B2C,
  #Full_Truck,
  #Hire_fleet,
  #Part_Truck {
    width: 100%;
    height: 100%;
  }

  .service_perticular_image .ecomm1 {
    position: relative;
    right: 0;
    width: 100%;
  }

  .document {
    position: relative;
    left: 0;
    width: 100%;
  }

  .air_fright {
    position: relative;
    width: 100%;
    margin-top: -100%;
  }

  #B2C {
    background-size: contain !important;
  }

  #B2C .service_perticular_image::before {
    position: absolute;
    width: 300px;
    height: 359px;
    /* background-size: cover; */
    background-size: 800px 600px;
    right: -6px;
    background-repeat: no-repeat;
    top: 0;
    background-size: contain;
    /* background-position: 800px 0; */
  }

  #Full_Truck .service_perticular_image::before {
    width: 300px;
    height: 359px;

  }

  .parttruck,
  .FullTruck,
  .hirefleet {
    position: relative;
    width: 100%;
    margin-top: -170% !important;
  }



  #Hire_fleet .service_perticular_image::before {
    content: "";
    position: absolute;
    width: 365px;
    height: 320px;
    background-size: contain;
    /* background-size: 800px 600px; */
    left: -70px;
    background-repeat: no-repeat;
    /* top: 0; */
  }

  .our_services {
    background-size: cover !important;
  }

  .main_picture img {
    width: 100%;
  }

  .step-part {
    display: flow !important;
  }

  .service_top_content h4 {
    font-size: 25px;
    color: #4656a3;
  }

  .all_serives_white {
    flex-wrap: wrap;
  }

  .all_services {
    margin-top: 2rem;
    flex-wrap: wrap;
  }

  .all_services p {
    font-size: 15px;
  }

  .dextop_all_services {
    display: none;
  }

  .all_services_card:hover {
    transform: scale(1);
    padding: 50px 20px;

  }

  .mobile_service_all_list {
    display: block;
  }

  .all_services .barrier::after {
    display: none;
  }

  .service-section h2 span {
    font-size: 50px;
  }

  .service-section h2 {
    font-size: 21px;
  }

  .mobile_ecom {

    background-repeat: no-repeat !important;
    width: 100%;
    height: 515px;
    background-size: contain !important;
  }

  .dextop_service_heading {
    display: block;
  }

  .service_perticular_content h2 {
    display: none;
  }

  .service-section {
    background-image: none !important;
  }

  .service_perticular_content {
    font-size: var(--main-paragragh--mobile);
    /* margin-top: 1rem; */

  }



  .service_perticular_content ul {
    font-size: var(--main-paragragh--mobile)
  }

  .service_top_content p {
    font-size: var(--main-paragragh--mobile)
  }

}

.section_weight_connect_mobile {
  display: none;

}

/* responive service page end */

/* responive ndr solutions page start */
@media (max-width: 767px) {
  .navigation_Section {
    background: linear-gradient(112deg, #4656a3 0.8%, #7939cc 100%);
  }

  .inner-banner .service_top_content h4 {
    font-size: 30px;
    color: #ffffff;
  }

  .ndr-mangement-section {
    padding: 10px;
    border-radius: 20px;
  }

  .ndr-mangemnet-part h2,
  .ndr-mangemnet-youtube h2 {
    font-size: 21px !important;
    font-size: 20px !important;
  }

  .section_weight_connect {
    display: none;

  }

  .section_weight_connect_mobile {
    display: block;
    height: 630px !important;
    padding: 2rem !important;
  }

  .ndr-mangemnet-part p {
    /* font-size: 12px; */
    margin: 1rem 0;
  }

  .ndr-mangemnet-part ol li,
  .ndr_features_section ol li {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .stages_section h5 {
    font-size: 18px;
  }

  /* .stages_section p {
    font-size: 12px;
  } */
  .stages_section h4 {
    font-size: 25px;
  }

  .ndr_features_section h4 {
    font-size: 17px;

  }

  .stages_section {

    text-align: start;
  }

  .ndr_features_section_picture img {

    width: 60%;
  }

  .why_ndr_Choose .main_difference {
    margin-bottom: 10px;
  }

  .main_difference {
    height: 60vh !important;
  }

  .main_difference p {
    font-size: 12px;
  }

  .ndr-mangemnet-part {
    padding: 1rem;
    height: 100% !important;
  }

  .resons_ndr_card p {
    font-size: 12px !important;
    /* display: none; */
  }

  .resons_ndr_card h5 {
    font-size: 15px !important;
    font-weight: 500;
  }

  .resons_ndr_card img {
    width: 15%;
  }

  .ndr_features_section_picture {
    text-align: right;
  }

  .resons_ndr_card {
    height: inherit;
    margin-bottom: 10px;
  }

  .resons_ndr_card:hover {
    border-radius: 14px;

  }

  .stages_section h4 {
    font-size: 25px;
  }

  .shiping_now {
    background-size: cover !important;
    background-position: left !important;
    border-radius: 20px;
  }

  .shiping_now_inside p {
    font-size: 12px;
  }

  .ndr_features_section,
  .cod_features_section,
  .pickup_features_section {
    background-image: none !important;
  }

  button.accordion-button {
    font-size: 14px !important;

  }

  .accordion-item .accordion-header {
    margin: 1rem 0;
  }

  .accordion .accordion-item .accordion-body {

    font-size: 12px;
  }
}


main {
  padding: 4rem;
}


main .foundation::before {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 50px;

  background: linear-gradient(180deg, #7939cc 0%, #4656a3 100%);
  position: absolute;
  left: -21px;
  top: -6px;
}

.timeline h4 {
  font-size: 80px;
  background: linear-gradient(180deg,
      rgba(70, 86, 163, 0.4) 0%,
      rgba(121, 57, 204, 0.4) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 4rem 0;
}

.timeline .foundation {
  border-left: 5px solid #b59ae2;
  border-bottom: 5px solid #b59ae2;
  height: 600px;
  position: relative;
}

.foundation ul {
  margin: 0;
  padding: 0 1rem;
}

.timeline .foundation::after {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50px;
  /* background: linear-gradient(180deg, #7939CC 0%, #4656A3 100%); */
  position: absolute;
  left: -15px;
  bottom: 50%;
  background: #fff;
  border: 2px solid #4656a3;
}

.timeline .foundation p:first-child {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #7939cc;
  border-radius: 150px;
  width: 200px;
  height: 200px;
  margin-left: 20px;
  text-align: center;
  align-items: center;
  display: flex;
  /* flex-direction: column; */
  position: absolute;
  top: -81px;
  left: 2%;
}

.timeline .foundation h6 {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #7939cc;
  border-radius: 150px;
  width: 100px;
  height: 100px;
  margin-left: 20px;
  text-align: center;
  align-items: center;
  display: flex;
  margin-top: 9rem;
  /* flex-direction: column; */
  position: absolute;
  bottom: 40%;
  /* left: 2%; */
}

.timeline .foundation_bottom {
  border-right: 5px solid #b59ae2;
  height: 600px;
}

.timeline .foundation_bottom,
.timeline .foundation_middle {
  position: relative;
}

.timeline .foundation_bottom_2023::before {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50px;

  position: absolute;
  right: -15px;
  bottom: 0;
  background: #fff;
  border: 2px solid #4656a3;
}

.timeline .foundation_bottom_2022::before {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50px;

  position: absolute;
  right: -15px;
  bottom: 45%;
  background: #fff;
  border: 2px solid #4656a3;
}

.timeline .foundation_bottom_2021::after {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50px;

  position: absolute;
  right: -15px;
  bottom: 80%;
  background: #fff;
  border: 2px solid #4656a3;
}

.timeline .foundation_bottom_2023 h6 {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #7939cc;
  border-radius: 150px;
  width: 100px;
  height: 100px;
  margin-left: 20px;
  text-align: center;
  align-items: center;
  display: flex;

  position: absolute;
  bottom: 0;
  right: 2%;
  margin: 0;
}

.timeline .foundation_bottom_2022 h6 {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #7939cc;
  border-radius: 150px;
  width: 100px;
  height: 100px;
  margin-left: 20px;
  text-align: center;
  align-items: center;
  display: flex;

  position: absolute;
  bottom: 38%;
  right: 2%;
  margin: 0;
}

.timeline .foundation_bottom_2021 h6 {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #7939cc;
  border-radius: 150px;
  width: 100px;
  height: 100px;
  margin-left: 20px;
  text-align: center;
  align-items: center;
  display: flex;

  position: absolute;
  bottom: 75%;
  right: 2%;
  margin: 0;
}

.foundation_middle_upper::after {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50px;
  /* background: linear-gradient(180deg, #7939CC 0%, #4656A3 100%); */
  position: absolute;
  right: 40%;
  bottom: -15px;
  background: #fff;
  border: 2px solid #4656a3;
}

.foundation_middle_lower::after {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50px;
  /* background: linear-gradient(180deg, #7939CC 0%, #4656A3 100%); */
  position: absolute;
  right: 60%;
  bottom: -15px;
  background: #fff;
  border: 2px solid #4656a3;
}

/* .timeline .foundation_middle_upper  {
position: relative;
} */
.timeline .foundation_middle_upper h6 {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 150px;
  width: 100px;
  height: 100px;
  margin-left: 20px;
  text-align: center;
  align-items: center;
  display: flex;
  /* flex-direction: column; */
  position: absolute;
  bottom: 5%;
  right: 38%;
  margin: 0;
}

.timeline .foundation_middle_lower h6 {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 150px;
  width: 100px;
  height: 100px;
  margin-left: 20px;
  text-align: center;
  align-items: center;
  display: flex;
  /* flex-direction: column; */
  position: absolute;
  bottom: -23%;
  right: 56%;
  margin: 0;
}

.foundation_2017 {
  border-radius: 8px;
  background: linear-gradient(94deg, #4656a3 1.72%, #7939cc 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  width: 300px;
  /* height: 100%; */
  padding: 15px;
  /* margin-left: 25%; */
  position: absolute;
  left: 23%;
}

.foundation_2018 {
  border-radius: 8px;
  background: linear-gradient(94deg, #4656a3 1.72%, #7939cc 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  width: 300px;
  /* height: 100%; */
  padding: 15px;
  /* margin-left: 15%; */
  position: absolute;
  /* margin-top: 13%; */
  bottom: 40%;
  left: 12%;
}

.foundation_2019 {
  border-radius: 8px;
  background: linear-gradient(94deg, #4656a3 1.72%, #7939cc 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  width: 300px;
  /* height: 100%; */
  padding: 15px;
  /* margin-left: 5%; */
  /* margin-top: 20%; */
  position: absolute;
  bottom: -22%;
  left: 8%;
}

.foundation_2020 {
  border-radius: 8px;
  background: linear-gradient(94deg, #4656a3 1.72%, #7939cc 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  width: 300px;
  /* height: 100%; */
  padding: 15px;
  /* margin-right: 5%; */
  /* margin-top: 0%; */
  position: absolute;
  right: 10%;
  bottom: 20px;
}

.foundation_2023 {
  border-radius: 8px;
  background: linear-gradient(94deg, #4656a3 1.72%, #7939cc 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  width: 300px;
  padding: 15px;

  position: absolute;
  right: 145px;
  bottom: 0;
}

.foundation_2022 {
  border-radius: 8px;
  background: linear-gradient(94deg, #4656a3 1.72%, #7939cc 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  width: 300px;
  /* height: 100%; */
  padding: 15px;
  /* margin-right: 5%; */
  /* margin-top: 0%; */
  position: absolute;
  right: 145px;
  bottom: 35%;
}

.foundation_2021 {
  border-radius: 8px;
  background: linear-gradient(94deg, #4656a3 1.72%, #7939cc 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  width: 300px;
  padding: 15px;
  position: absolute;
  right: 145px;
  bottom: 70%;
}


.foundation_bottom .foundation_bottom_picture:first-child {
  position: absolute;
  top: 30%;
}

.foundation_bottom .foundation_bottom_picture img {
  width: 25%;
  display: block;
}

.foundation_bottom .foundation_bottom_picture img:nth-child(2) {
  width: 25%;
  display: block;
  position: absolute;
}

.timeline::before {
  content: "";
  position: absolute;
  background: url("/public/image/aboutus/story/Group1591.png");
  width: 25%;
  /* top: 40%; */
  height: 60%;
  /* left: 250px; */
  background-repeat: no-repeat;
  z-index: 2;
  padding: 10px;
  background-size: contain;
  right: 0;
}

.timeline {
  position: relative;
}

.timeline::after {
  content: "";
  position: absolute;
  background: url("/public/image/aboutus/story/Group1723.png");
  width: 30%;
  bottom: 0;
  height: 35%;
  left: 0;
  /* top: 50%; */
  bottom: 0;
  background-repeat: no-repeat;
  z-index: 2;
  background-size: contain;
  background-position: left;
}

/* timeline end*/

.contact_section .right_side img {
  width: 100%;
  /* margin-top: 3rem; */
}

.btn-outline-light {
  padding: 10px 100px !important;
  font-size: 18px !important;
  position: relative !important;
  z-index: 1 !important;
  color: #fff !important;
}

.btn-outline-light:hover {
  color: #4656a3 !important;
  background-color: #fff !important;
}

.btn-outline-light:hover:after {

  background-color: transparent !important;
  border-radius: .25rem !important;
  content: "" !important;
  display: block !important;
  height: 45px !important;
  border: 2px solid #fff !important;
  left: 0 !important;
  width: 100% !important;
  position: absolute !important;
  top: -2px !important;
  transform: translate(7px, 11px) !important;
  transition: transform 0.2s ease-out !important;
  z-index: -1 !important;

}

.navigation_Section .outline-login {
  padding: 8px 25px !important;
  position: relative !important;
  z-index: 1 !important;
  color: #fff !important;
  border: 2px solid #fff;
  font-weight: 600;
}

.navigation_Section .outline-login:hover {
  color: #7939cc !important;
  background: #fff !important;
}

.navigation_Section .outline-login:hover:after {

  background-color: transparent !important;
  border-radius: .25rem !important;
  content: "" !important;
  display: block !important;
  height: 40px !important;
  border: 2px solid #fff !important;
  left: 0 !important;
  width: 100% !important;
  position: absolute !important;
  top: -1px !important;
  transform: translate(7px, 11px) !important;
  transition: transform 0.2s ease-out !important;
  z-index: -1 !important;

}

.btn-light {
  padding: 15px 100px !important;
  margin-top: 1rem;
  height: fit-content;
  position: relative;
  background-color: #fff !important;
  z-index: 1;
  color: #4656a3 !important;
}

.btn-light:hover {

  background-color: #fff !important
}

.btn-light:hover:after {

  content: "" !important;
  background-color: transparent !important;
  border: 2px solid #fff !important;
  border-radius: 0.25rem !important;
  display: block !important;
  height: 51px !important;
  width: 100%;
  left: -3px !important;
  position: absolute !important;
  top: -12px !important;
  transform: translate(10px, 22px) !important;
  transition: transform 0.2s ease-out !important;
  z-index: -1 !important;

}

.btn-outline-light:focus,
.btn-light:focus {
  box-shadow: none !important
}

.let_connect {
  border-radius: 16px;
  background: linear-gradient(91deg, #4656a3 0%, #7939cc 100%);
  padding: 10px;
  color: #fff;
  text-align: center;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}

.let_connect_carrer {
  border-radius: 16px;
  background: linear-gradient(91deg, #4656A3 0%, #008 0%, #7939CC 100%);


  padding: 10px;
  color: #fff;
  text-align: center;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}

.let_connect .let_connect_blur,
.let_connect_carrer .let_connect_blur {
  border-radius: 16px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(21px);
  padding: 18px;


}

.Guidelines_developers img {
  margin-bottom: -10%;
  margin-left: 10%;

}

.Innovation p {
  text-align: justify;
  font-size: var(--main-paragragh-laptop);
}

.Innovation h4,
.Innovation_carrer h4,
.map_shipping_track h4,
.integration_picture h4 {
  margin: 2rem 0;
  color: #7939cc;
  font-weight: bold;
  font-size: 35px;
}

.let_connect .let_connect_blur h4,
.let_connect_carrer .let_connect_blur {
  margin: 0;
}

.contact_number {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-size: 20px;
  flex-wrap: wrap;
}

.contactus_form label {
  margin: 0;
  font-weight: bold;
  color: #4656A3;
}

.contact_number img {
  width: 30px;
}

.contactus_form input {
  line-height: 2 !important;
}

.colling p {
  margin-bottom: 2rem;
  font-size: var(--main-paragragh-laptop);
}

.colling img {
  width: 30px;
  margin-right: 20px;
}

.map {

  padding: 35px;
  border-radius: 32px;
  border: 2px solid #7939cc;
}

.media_section .right_side img {
  width: 100%;
  /* margin-top: 2rem; */
}

.Innovation .right-side {
  text-align: center;
  border-radius: 50%;
  padding: 35px;
  /* width: 430px;
  height: 430px; */
  margin: auto;
  background: linear-gradient(178deg,
      #6b80ce 1.64%,
      rgba(121, 57, 204, 0.4) 138.38%);
}

.Innovation_carrer .right-side {
  text-align: center;
  border-radius: 50%;
  padding: 35px;
  /* width: 430px;
  height: 430px; */
  margin: auto;

  background: linear-gradient(178deg, #6B80CE 1.64%, #008 138.38%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.Innovation .right-side img {
  width: 100%;
  height: 100%;
}

.Innovation_carrer .right-side img {
  width: 100%;
  height: 100%;
}

.magzin_bg {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.magzine_section img {
  width: 100%;
  border-radius: 8px;
  aspect-ratio: 3/4;
  object-fit: cover;
}

/* .media_section .right-side img{
  width: ;
} */

.magzine_section h6 {
  border-radius: 8px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(21px);
  color: #fff;
  padding: 10px;
  margin-top: 10px;
}

.magzine_section h2 {
  color: #fff;
  font-size: 55px;
  margin-bottom: 2rem;
}

.up_coming_magazine {
  width: 100%;
  border: 2px dashed #fff;
  border-radius: 8px;
  aspect-ratio: 3/4;
  object-fit: cover;
  color: #fff;
  text-align: center;
  align-items: center;
  display: grid;
  text-transform: capitalize;
}

.wrapper_dashborad {
  text-align: center;
  color: #fff;
  margin-top: 5rem;
}

.wrapper_dashborad h2 {
  font-weight: bold;
  font-size: 45px;
}

.our_vission {
  border-radius: 8px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%, #4656A3 0%, #7939CC 100%);
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
  text-align: center;
  color: #fff;
  transform: rotate(270deg);
  width: 181px;
  height: auto;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: -4%;

}

.our_vission p {
  margin: 0;
}

@media (max-width: 767px) {
  .magzine_section h6 {

    font-size: 10px;
  }

  .aboutus_section {
    padding-top: 1rem;
  }

  .aboutus_section h2 {
    font-size: 40px;
  }

  .vission_section {
    flex-wrap: wrap;
  }

  .wrapper_vission .vission_count {
    background-color: #fff;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 8px;
    /* flex-direction: column; */
    text-align: center;
    /* align-items: center; */
    position: absolute;
    margin-top: -30px;
    margin-left: -25px;
  }

  .vission_style .white_bg {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 25px;
    width: 100%;
    height: 85px;
    display: flex;

    align-items: center;
  }

  .wrapper_vission .vission_count p {
    font-size: 25px;
  }


  .vission_style {
    margin-bottom: 2rem;
  }

  .wrapper_vission .vission_style.alternate {
    margin-left: 70px !important;
  }

  .vission_section_part h2 {
    font-size: 50px;
  }

  .vission_section {
    background-image: none !important;
  }

  .vission_section_part {

    margin-top: 3rem;
  }

  .right_side_animation {
    position: relative;
    bottom: 0;
    right: -15px;
  }

  .indiagate {
    float: right;
  }

  .vission_section_who p {
    margin-right: 0;
    text-align: justify;
  }

  .about-top-part img {
    width: 100%;
  }

  .timeline {
    position: relative;
  }

  .timeline::before {
    content: "";
    position: absolute;

    width: 60%;
    top: -360px;
    /* height: 60%; */
    right: 0;
    /* left: 250px; */
    background-repeat: no-repeat;
    z-index: 2;
    padding: 10px;
    background-size: contain;
    background-position: right;
  }

  .timeline::after {

    width: 27%;
    bottom: 0;
    height: 21%;
    left: 0;
    display: none;

  }

  main {
    padding: 20rem 10px 10px 10px;
    font-size: 12px;
  }

  .foundation_2017 {
    border-radius: 8px;
    background: linear-gradient(94deg, #4656a3 1.72%, #7939cc 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;

    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 14%;
  }

  .foundation_2018 {
    border-radius: 8px;
    background: linear-gradient(94deg, #4656a3 1.72%, #7939cc 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    width: 60%;

    position: absolute;
    /* margin-top: 13%; */
    bottom: 40%;
    left: 35%;

  }

  .timeline .foundation p:first-child {
    background-color: #fff;
    padding: 30px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 150px;
    width: 150px;
    height: 150px;
    margin-left: 20px;
    text-align: center;
    align-items: center;
    display: flex;
    /* flex-direction: column; */
    position: absolute;
    top: -81px;
    left: 2%;
    font-size: 20px;

  }

  .timeline .foundation_middle_upper h6 {
    background-color: #fff;
    padding: 30px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 150px;
    width: 80px;
    height: 80px;
    margin-left: 20px;
    text-align: center;
    align-items: center;
    display: flex;
    /* flex-direction: column; */
    position: absolute;
    bottom: -20%;
    right: 60%;
    left: 0;
    margin: 0;
  }

  .timeline .foundation h6 {
    padding: 20px;

    width: 80px;
    height: 80px;
  }

  .foundation_middle_lower::after {
    content: "";
    width: 25px;
    height: 25px;
    border-radius: 50px;
    /* background: linear-gradient(180deg, #7939CC 0%, #4656A3 100%); */
    position: absolute;
    bottom: 15%;
    left: -15px;
    background: #fff;
    border: 2px solid #4656a3;
  }

  .timeline .foundation_middle_lower h6 {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 150px;
    width: 80px;
    height: 80px;
    margin-left: 20px;
    text-align: center;
    align-items: center;
    display: flex;
    /* flex-direction: column; */
    position: absolute;
    bottom: 11%;
    right: 0;
    left: 20px;
    margin: 0;
  }

  .foundation_2019 {
    border-radius: 8px;
    background: linear-gradient(94deg, #4656a3 1.72%, #7939cc 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    /* font-size: var(--main-paragragh--mobile); */
    width: 60%;
    padding: 20px 0;
    /* margin-left: 5%; */
    /* margin-top: 20%; */
    position: absolute;
    bottom: 10%;
    left: 33%;
    /* right: 0; */
    /* margin: auto; */
  }

  .foundation_middle_upper::after {
    content: "";
    width: 25px;
    height: 25px;
    border-radius: 50px;
    /* background: linear-gradient(180deg, #7939CC 0%, #4656A3 100%); */
    position: absolute;
    right: 50%;
    bottom: -15px;
    background: #fff;
    border: 2px solid #4656a3;
  }

  .foundation_2020 {
    border-radius: 8px;
    background: linear-gradient(94deg, #4656a3 1.72%, #7939cc 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    width: 60%;

    position: absolute;
    right: 20px;
    bottom: -32%;
  }

  .foundation_2021 {
    width: 60%;
    bottom: 50%;
  }

  .timeline .foundation_bottom {

    height: 900px;
  }

  .foundation_2022 {

    width: 60%;

    bottom: 25%;
  }

  .timeline .foundation_bottom_2023 h6 {

    right: 6%;

  }

  .foundation_bottom .foundation_bottom_picture:first-child {
    display: none;
  }

  .foundation_2023 {
    border-radius: 8px;
    background: linear-gradient(94deg, #4656a3 1.72%, #7939cc 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    width: 200px;

    position: absolute;
    /* right: 145px; */
    bottom: 0;
  }

  .timeline .foundation_bottom_2021 h6 {

    bottom: 55%;
    right: 6%;

  }

  .timeline .foundation_bottom_2021::after {

    right: -15px;
    bottom: 60%;

  }

  .timeline .foundation_bottom_2022::before {
    bottom: 30%;

  }

  .timeline .foundation_bottom_2022 h6 {
    bottom: 25%;
    right: 6%;

  }

  .future_section {
    /* height: 60vh !important; */
    /* background-size: cover !important; */
    padding: 20px !important;
  }

  .future_section h1 {
    font-size: var(--main-card-heading-mobile);
  }


  .wrapper_future ul {
    font-size: var(--main-paragragh-laptop);
    /* line-height: 2; */
  }

  .wrapper_future {
    width: 100%;
    /* height: 300px; */
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .service_slider .box-area {
    width: 100%;
    height: 50vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }

  .service_slider .boxes {
    width: 600px;
    height: 100%;
    margin-right: 5px;
    /* overflow: hidden; */
    border-radius: 20px;
    position: relative;
    transition: all linear 0.6s;
  }

  .service_slider .box-area .active {
    width: 10000px;
    transition: all linear 0.6s;
  }

  .seller_section {
    background-size: cover !important;
  }

  .shiping_now_inside_bussiness {
    display: inherit;
    padding: 2rem !important;
  }

  .service_slider h4 {
    color: #fff;
    font-size: 30px;
    text-transform: capitalize;
  }

  .seller_section {
    background-position: left !important;
    background-size: cover !important;
  }

  .timeline h4 {
    font-size: 60px;
  }

  .shiping_now_inside_bussiness h4 {
    font-size: 40px;
  }

  .fixed_height {

    grid-template-columns: repeat(1, 1fr) !important;
    margin-bottom: 20px;
  }
}

/* media page responsive start */
@media (max-width: 767px) {
  .toaster_header .toast {
    width: 90%;

  }

  .media_section h4 {
    font-size: 2rem;
  }

  .media_section .right_side {
    margin-top: 3rem;
  }

  .let_connect {
    width: 100%;
  }

  .let_connect h4 {
    font-size: 18px;
  }

  .Innovation .right-side {
    padding: 25px;
    width: 300px;
    height: 300px;
  }

  .Innovation .right-side img {
    width: 250px;
    height: 250px;
  }

  .magzine_section h2 {
    font-size: 35px;
  }

  .magzine_section {
    padding: 1rem !important;
  }

  .Innovation h4 {
    font-size: var(--main-card-heading-mobile);
  }

  .Innovation p {
    font-size: var(--main-paragragh--mobile);
    text-align: justify;

  }
}

/* media page responsive end */

/* contactus page responsive start*/
@media (max-width: 767px) {

  .btn-light {
    padding: 15px 50px !important;
  }

  .contact_section h4 {
    font-size: 30px;
  }

  .contact_section .right_side img {
    width: 100%;
  }

  .map {
    margin-bottom: 10px;
    padding: 20px;
  }

  .contact_number {
    display: inherit;
    /* justify-content: space-between; */
    /* gap: 20px; */
    font-size: 20px;
    flex-wrap: wrap;
    /* margin: auto; */
    text-align: center;
  }

  .customer_connect {
    padding: 0 !important;
    height: 150px !important;
  }

  .contact_signup {
    background-size: cover !important;
    background-position: right !important;
  }

  .contactus_form {
    padding: 10px !important;
    background-position: right !important;
  }

  .colling p {
    margin-bottom: 2rem;
    font-size: var(--main-paragragh--mobile);
  }

  .btn-outline-light {
    padding: 10px 35px !important;
    font-size: 14px !important;
    position: relative !important;
    z-index: 1 !important;
    color: #fff !important;
    margin-bottom: 1rem;
  }

  .faq_section,
  .wrapper_faq_section {
    padding: 1rem;
    margin-top: 20px;


  }

  .our_vission {

    transform: none;
    width: 100%;

    font-size: 30px;
    position: relative;
    top: 0;
    left: 0;
  }

  .blog-ads-banner img {
    width: 100% !important;
  }
}

/* contactus page responsive end*/


.cod .over_view img {
  border-top-right-radius: 16px;

}

.early-numbers {
  margin-top: 2rem;
  display: flex;


}

.early-numbers {
  margin-top: 2rem;
  display: flex;
  text-align: justify;

}

.early-numbers h4 {
  font-weight: bold;
  margin-bottom: 20px;

}

.early-numbers_pic {
  text-align: center;
}

.early-numbers_pic img {
  width: 100%;
}

.early-numbers h5 {
  font-size: 120px;
  margin-top: -50px;
  font-family: math;
}

.early-numbers .number_content {
  margin-left: 40px;
}

.early_cod::before {
  content: "";
  position: absolute;
  width: 70%;
  height: 70%;
  left: -12%;

  bottom: -3%;
  background-image: url("/public/image/solution_folder/cod/early_design_element.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.early_cod::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  right: -380px;
  top: -4%;
  bottom: -3%;
  background-image: url("/public/image/solution_folder/cod/early_design_element_2.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.cod_features_section {
  background-image: url("/public/image/solution_folder/cod/7.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.pickup_features_section {
  background-image: url("/public/image/solution_folder/multipal_pickup_location/5.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}


.cod_features_section_picture {
  text-align: end;
}

.cod_features_section img {
  width: 22%;
  float: right;
  text-align: end;
  /* margin-right: -15%; */
}

.Remittance_process {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.cod-connect,
.api-connect {
  display: flex;
  background: radial-gradient(155.5% 60.52% at 50.03% 59.53%, #7939CC 0%, #4656A3 100%);
  gap: 20px;
  padding: 3rem 5rem;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  z-index: 1;
}


.inside_connect {
  display: flex;
  justify-content: space-between;
  border: 2px solid #fff;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
  backdrop-filter: blur(21px);
  padding: 1rem 4rem;
  z-index: 2;
  border-radius: 30px;
  position: relative;
}

.cod-connect img {
  width: 50%;
}


.cod-connect::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 100%;
  left: -6%;
  bottom: -3%;
  background-image: url("/public/image/solution_folder/cod/Group1656.png");
  background-repeat: no-repeat;
  background-size: contain;
}


.cod-connect::after {
  content: "";
  position: absolute;
  width: 500px;
  height: 800px;
  right: -19%;
  top: -25%;
  background-image: url("/public/image/solution_folder/cod/Group1658.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.weight_connect::before,
.weight_connect::after {
  display: none !important;
}

.cod_glass {
  padding-left: 40px;
  border-radius: 32px 0px 0px 32px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
  backdrop-filter: blur(21px);
}

.cod_glass_image {
  border-radius: 0 30px 30px 0;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
  backdrop-filter: blur(21px);
  text-align: center;
  padding: 20px;
}


.api-connect::before {
  content: "";
  position: absolute;
  width: 500px;
  height: 100%;
  left: 0;
  bottom: -3%;
  background-image: url("/public/image/solution_folder/api/connect_element1.png");
  background-repeat: no-repeat;
  background-size: contain;
}



.api-connect::after {
  content: "";
  position: absolute;
  width: 500px;
  height: 100%;
  right: -10%;
  top: 55%;
  background-image: url("/public/image/solution_folder/api/connect_element2.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.inside_connect p {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.cod-connect p {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.pickup_process::before {
  content: "";
  position: absolute;
  width: 150px;
  height: 100%;
  left: -30px;
  top: 30%;
  background-image: url("/public/image/solution_folder/multipal_pickup_location/element_1.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.pickup_process::after {
  content: "";
  position: absolute;
  width: 59%;
  height: 100%;
  right: -4%;
  top: -8%;
  background-image: url("/public/image/solution_folder/multipal_pickup_location/element.png");
  background-repeat: no-repeat;
  background-size: cover;
}




.multiple_connect {
  text-align: center;
  border-radius: 32px;
  background: linear-gradient(278deg, #7939CC 0%, #008 50.23%, #4656A3 100%);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);
  overflow: hidden;
  color: #fff;
  position: relative;
}

.multiple_connect h2 {
  font-size: 40px;
  color: #fff;
}

.multiple_connect p {
  font-size: 20px;

}

.multiple_connect::before {
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  left: -50px;

  bottom: -50px;
  background-image: url("/public/image/solution_folder/multipal_pickup_location/element1.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.multiple_connect::after {
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  right: -50px;

  top: -50px;
  background-image: url("/public/image/solution_folder/multipal_pickup_location/element2.png");
  background-repeat: no-repeat;
  background-size: cover;
}


.wrapper_multiple_connect::before {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  text-align: center;
  top: 15%;
  background-image: url("/public/image/solution_folder/multipal_pickup_location/element3.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  left: 10%;
}

.wrapper_multiple_connect::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  text-align: center;
  top: 15%;
  background-image: url("/public/image/solution_folder/multipal_pickup_location/element4.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  right: 10%;
}

.wrapper_multiple_connect {
  padding: 8rem 0;
}

.team_connect {

  width: 100%;
  height: 400px;
  border-radius: 32px;
  background: radial-gradient(155.5% 60.52% at 50.03% 59.53%, #008 0%, #4656A3 100%);
  overflow: hidden;
  color: #fff;
  position: relative;
}

.team_connect::before {
  content: "";
  position: absolute;
  width: 300px;
  text-align: center;
  top: -240px;
  height: 800px;
  background-image: url("/public/image/solution_folder/multipal_pickup_location/element5.png");

  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  left: -71px;
}

.team_connect::after {
  content: "";
  position: absolute;
  width: 400px;
  text-align: center;
  top: -90px;
  height: 760px;
  background-image: url("/public/image/solution_folder/multipal_pickup_location/element6.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  right: -63px;
}

.wrapper_team_connect {
  display: flex;
  gap: 20px;
  /* justify-content: space-between; */
}

.wrapper_team_connect {
  padding: 2rem;
}

.wrapper_team_connect img {
  width: 100%;

}

.wrapper_pic {
  border-radius: 32px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
  backdrop-filter: blur(21px);
  padding: 1rem;

  width: 350px;
  height: 350px;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper_team_inside {
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
  width: 60%;
  /* height: 300px; */
  flex-shrink: 0;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 32px 0px 0px 32px;
  background: radial-gradient(231% 135.8% at 0.9% 2.98%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
  backdrop-filter: blur(21px);
  margin: 2rem 0;
  position: relative;
}

.wrapper_team_inside::before {
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  right: 0;
  top: 120px;
  background-image: url("/public/image/solution_folder/multipal_pickup_location/Group1670.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.cod_features_section ul li {
  font-size: 20px;
  color: #7939CC;
}

.cod_features_section ul li p {
  font-size: 15px;
  color: #000;
}

.weight-stages_pic {
  text-align: center;
}

.weight-stages_pic img {
  width: 80% !important;
  max-height: 450px;
}

.weight-stage_content h5 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 25px;
}

.weight-stage_content {
  text-align: justify;
}

.weight-stage_content ul li {
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 15px;
}



.weight_stages::before {
  content: "";
  position: absolute;
  width: 345px;
  height: 100%;

  left: -280px;
  top: 0;
  background-image: url("/public/image/solution_folder/weight/element.png");
  background-repeat: no-repeat;
  background-size: cover;

}

.weight-stage_content {
  font-size: var(--main-paragragh-laptop);
}

.weight-stage_content p {
  font-size: 15px
}

.weight_stages::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 100%;
  right: -100px;
  top: -83px;
  background-image: url("/public/image/solution_folder/weight/element_after.png");
  background-repeat: no-repeat;
  background-size: cover;

}

.weight_stages .row {
  margin-bottom: 5rem !important;
}

.Integration_steps::before {
  content: "";
  position: absolute;
  background: url("/public/image/solution_folder/api/element1.png");
  left: -10%;
  top: 0;
  width: 220px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;

}

.Integration_steps::after {
  content: "";
  position: absolute;
  background: url("/public/image/solution_folder/api/element2.png");
  right: -10%;
  top: 0;
  width: 320px;
  height: 5000px;
  background-size: contain;
  background-repeat: no-repeat;
  /* z-index: -1; */
}

.termconnect {
  border-radius: 32px;
  background: radial-gradient(201.36% 79.9% at 64.71% 72.33%, rgba(0, 0, 136, 0.52) 0%, #4656A3 100%);


  padding: 3rem 5rem;

  overflow: hidden;
  border-radius: 30px;
  z-index: 1;
  position: relative;
}

.termconnect::before {
  content: "";
  position: absolute;
  background-image: url("/public/image/TermandCondition/element.png");
  top: -69px;
  width: 200px;
  left: -69px;
  height: 100%;
  /* background-position: center; */
  background-size: contain;
  background-repeat: no-repeat;
}

.termconnect::after {
  content: "";
  position: absolute;
  background-image: url("/public/image/TermandCondition/element.png");
  bottom: -60px;
  width: 200px;
  right: -69px;
  height: 200px;
  /* background-position: center; */
  background-size: contain;
  background-repeat: no-repeat;
}

.termconnect .term-img img {
  width: 60%;
}




.button-14 {
  background: linear-gradient(97deg, #4656a3 1.25%, #7939cc 102.15%);
  border-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, .6) 0 1px 0 inset;
  color: #fff;


}

.button-14:active {
  border-bottom-color: #a2a6ac;
}

.button-14:active:hover {
  border-bottom-color: #a2a6ac;
}

.button-14:hover {
  border-color: #a2a6ac #979aa1 #82858a;
}

.button-14:focus {
  border-color: #16e851;
  box-shadow: rgb(19 150 36 / 50%) 0 0 3px 2px;
  outline: 0;
}


.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";

  background: linear-gradient(45deg, #7939cc, #6e29c8, #5c2a9d, #8052bc, #6643bd, #44ec2c, #94f548, #6ef544, #90f43c);

  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;

  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #5f71b4;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.custom-svg {
  background-color: #7939cc;
  padding: 10px 15px;

}

.custom-svg-two {
  background-color: #7939cc;

  padding: 10px 15px;

}

.status_content {
  text-align: center;
  position: relative;

}



hr {
  border: 1px solid #7939CC !important;
}

.filter_blogs h1 {
  font-weight: bold;
  color: #7939CC;
  font-size: 30px;
}



@media (max-width:767px) {
  .glass_content {

    height: 100%;


  }

  .solution_items img,
  .resource_items img {
    width: 25px;

  }

  .blog-details h2 {
    font-size: 25px;
    margin-top: 0;
  }

  .pincode_courrier_warraper,
  .pincode_center,
  .Serviceability_icon,
  .oda_icon {

    text-align: start;
  }

  .cal_buttons {

    flex-wrap: wrap;

  }

  .form_calcutor {

    flex-wrap: wrap;
  }

  .dimension_part .form_calcutor {
    flex-wrap: nowrap !important;
  }

  .calculator_section h5 {
    margin-bottom: 25px;

  }

  .form_calcutor {

    margin-bottom: 5px;

  }

  .calculator_section label {
    font-size: 12px;

  }

  .navbarSection .navbar.sticky {

    top: 2%;

  }

  .shiping-rate_cal {

    padding: 5px;

  }

  .shiping-rate_cal .shiping_main_content {
    /* display: none; */
    font-size: 14px;
    text-align: justify;
    padding: 10px;
  }

  .shiping-rate_cal h2 {
    padding: 10px;
    font-size: 22px;
  }

  .shiping-rate_cal::after {
    display: none;
  }

  .weight_stages h2 {
    width: 100%;

  }

  .reg_btn {
    padding: 8px 16px !important;
    font-size: 15px !important;
  }

  .early_cod,
  .pickup_process,
  .weight_stages {
    padding: 2rem !important;
  }

  .early-numbers_pic img {
    width: 100%;
  }

  .early_cod::before {

    left: -39%;

  }

  .early_cod::after {

    right: -86px;
    top: 0;



  }

  .cod-connect::after {

    width: 100px;
    height: 100%;
    right: -40px;

  }


  .cod-connect p {
    font-size: 16px;

    text-align: justify;
  }

  .cod-connect {
    padding: 1rem;
  }

  .cod-connect img {
    width: 100%;
    margin-top: 10px;
  }

  .Remittance_process {

    flex-wrap: wrap;
  }

  .cod_features_section img {
    width: 60% !important;
    margin: auto;
  }

  .shiping_now_inside p {
    font-size: 15px;
    margin-top: 15px;
    font-weight: 300;
  }

  .shiping_now_inside h1 {
    font-size: 17px;

  }

  .pickup_process::before {
    display: none;
  }

  .early-numbers p {
    font-size: 12px;
    text-align: justify;
  }

  .cod-connect,
  .api-connect {

    padding: 3rem 2rem !important;

  }

  .inside_connect {
    padding: 1rem;
  }

  .Remittance_process ul li {
    font-size: 16px;
    color: #7939CC;
  }

  .Remittance_process ul li p {
    font-size: 12px;
    color: #000;
  }

  .wrapper_pic {

    padding: 2rem;
    width: 300px;
    height: 300px;

    margin: auto;
  }



  .team_connect {
    height: auto;
  }

  .pickup_process::after {
    display: none;
  }

  .wrapper_team_connect {

    flex-wrap: wrap;
  }

  .wrapper_team_inside {
    width: 100%;
    height: auto;
    margin: 0;
    border-radius: 32px;
    z-index: 2;
  }

  .team_connect::after {


    height: 850px;

  }

  .early-numbers .number_content {
    margin-left: 15px;
  }

  .early-numbers h5 {
    font-size: 80px;
    margin-top: -20px;

  }

  .offcanvas-body,
  .offcanvas-header {

    padding: 1rem 2rem !important;

  }

  .cod_glass_image,
  .cod_glass {
    width: 100% !important;
  }

  .cod-connect,
  .api-connect {
    flex-wrap: wrap;
  }

  .cod_glass {
    padding: 20px;
    border-radius: 20px;
  }

  .map_shipping_track {
    background-size: cover !important;
    padding: 0 !important;
  }

  section.contact_section.track_section {
    background-position: right !important;
  }

  .team_connect::before {
    display: none;
  }

  .wrapper_multiple_connect {
    padding: 4rem 2rem;
  }

  .wrapper_multiple_connect::after {

    width: 100px;
    height: 100px;

    top: 70%;
    right: 0%;
  }

  .wrapper_multiple_connect::before {

    width: 100px;
    height: 100px;
    left: 0;
    top: 0%;
  }

  .multiple_connect h2 {
    font-size: 30px;
    color: #fff;
  }

  .multiple_connect p {
    font-size: 15px;
  }

  .multiple_connect::after,
  .multiple_connect::before {

    width: 100px;
    height: 100px;

  }

  .weight_stages::before,
  .weight_stages::after {
    display: none;

  }

  .weight-stages_pic img {
    width: 100% !important;
  }

  .wrapper_pinocode {
    padding: 2rem;
  }

  .pinoce_flex_wrapper {

    flex-wrap: wrap;
    gap: 30px;
  }

  .pincode_courrier_warraper,
  .pincode_center,
  .Serviceability_icon {
    width: 100%;
    text-align: left;
  }

  .carrer-form .form-group {
    width: 100%;
  }

  .carrer-form {
    grid-gap: 20px;
    grid: none;
  }

  .all_serives_white .col-md-2::after {
    display: none;
  }
}



/* for all tablet screen device */
@media screen and (min-width:768px) and (max-width:1023px) {
  .calculator_section {
    margin-top: 2rem;
  }

  .shiping_now_inside {
    font-size: 22px !important;
  }

  .magzine_section h2 {

    font-size: 40px;

  }

  .magzine_section {
    padding: 3rem !important;
  }

  .home_slider {
    margin-top: 2rem;
  }

  .latestthree {
    display: none;
  }

  .blog_slider {
    display: block;
  }

  .all_services_card {
    padding: 0 !important;

  }

  .off_canvas {
    display: block;
  }


  .nav_reg {
    display: block;
  }

  .hero-banner h1 {
    font-size: 40px !important;

  }


  .home_slider .box-area {
    width: 100%;
    height: 65vh;

  }

  .dextop_service_heading {
    display: block;
  }

  .integration_picture h4 {
    display: none;
  }

  .home_slider .box-area .active {
    width: 100%;
    transition: all linear 0.6s;
  }

  .home_slider .box p {
    font-size: 16px;
  }

  /* .tracking_shipment input.form-control{
  padding: 5px;
}
.button-34 {

  font-size: 12px;

  padding: 2px 20px;

} */
  .toaster_header .toast {
    width: 75%;
    /* transform: translate(-50%, -50%); */
  }

  .gradient_card,
  .benifits_customer .custom_gradient {

    padding: 1rem;

  }

  .hero-banner {
    height: inherit !important;
    background-size: cover !important;
    background-position: left !important;
    padding: 12% 0px 2% !important;
  }

  .herocard-section .choose_wrapper {

    padding: 20px;

    /* height: 360px; */
  }

  .herocard-section .choose_wrapper img {
    width: 100px;

    height: 100px;

  }

  .dashboard_section h4,
  .partner-trust h4,
  .shiping_partner h4,
  .testimonials-section h4 {
    font-size: 30px !important;
  }

  .shiping_partner {
    padding: 3rem 0 !important;
  }

  .order-status-section h4,
  .process-section h4,
  .rate-calculator2 h4,
  .benifits_customer h4,
  .herocard-section h4,
  .services_componets h4,
  .conversionslider h4,
  .integration h4,
  .blog-section h4,
  .stages_section h4 {
    font-size: 30px;
    font-weight: bold;
    color: #0b0757;
    margin-bottom: 2rem;
  }

  .process-section .client,
  .process-section .Orders,
  .process-section .Experts,
  .process-section .Deliveries,
  .process-section .Integrations,
  .process-section .pincodes {
    padding: 25px 8px !important;
    border-radius: 20px;
  }

  .working-featured h3 {
    font-size: 25px;

  }

  .working-featured p {
    font-size: 14px
  }

  .working-featured h3:after {

    top: 60px;

  }

  .order-status-parts p {
    color: #ffffff;
    font-size: 10px;
    height: inherit;
    /* height: 70px; */
    display: none;
  }

  .order-status-parts img {
    width: 30px;
  }

  .order-status-parts h6 {
    font-size: var(--main-card-small-heading-tablet);
    line-height: initial;
    margin: 12px 0 12px;

  }

  .services_componets .cards_services {
    padding: 18px;

  }

  .glass_content {

    height: 100%;

  }

  .status_content {
    position: relative;
    padding-top: 20px;
  }

  .order-status-parts .status_content::before {

    width: 35px;
    height: 35px;
    top: 40%;
    right: -65%;
  }

  .integration_picture_magento {
    font-size: var(--main-paragragh-tablet)
  }

  .intrgation_gif {
    position: absolute;
    /* bottom: -3%; */
    right: 0;
    width: 40%;
    /* top: auto; */
  }

  .margin_top_bottom {
    margin-top: 2rem;
  }

  .blog-content {
    padding: 20px;
  }

  .blog-content h3 {
    font-size: 14px !important;
  }

  .blog-card .blog-content p,
  .blog-content .limit p span {
    font-size: 12px !important;

  }

  .blog-content small {

    font-size: 10px;
  }

  .blog-content {
    padding: 40px 20px !important;
  }

  .newsletter_input {
    width: 100% !important;
    /* height: inherit !important; */
    padding: 10px 30px;
    border-radius: 10px;

    background: #FFF;

  }

  .newsletterinside {
    padding: 40px;
  }

  .newsletterinside p {
    font-size: 12px;
  }

  .news_letter_section h2 {
    font-size: 25px !important;

  }

  .newsletterwrapper {
    padding: 2rem;

  }

  .footer-section .monuments_footer h2 {

    font-size: 24px;

  }

  .cards_glass h5,
  .bg_benifits_card h6,
  .conversion_content h6,
  .working-featured h3 {
    font-size: 20px;
  }

  .bg_benifits_content p,
  .herocard-section .choose_wrapper p,
  .glass_content p {
    font-size: var(--main-paragragh-tablet);
    margin: 0;
  }

  .rate-calculator2 img {
    width: 75%;
  }

  .rate-content ul li,
  .rate-content p {
    /* font-size: var(--main-paragragh-tablet); */
    text-align: justify;
  }

  .blog-section .wrapper_slides {
    margin-left: 10px;
  }

  .footer-logo {
    width: 35%;
  }

  .dashboard_section {
    height: 100% !important;
    padding: 2rem !important;
  }

  .dashboard_section {

    padding: 3rem 1rem !important;
    height: 55vh !important;
    background: url("/public/image/dashboad_client.png") #574cb1 !important;
    width: 100%;
    background-position: bottom;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: bottom !important;
  }

  .invisble-choose_wrapper {
    display: block;
  }

  .solution_body,
  .resource_body {
    width: inherit;

    box-shadow: none;
  }

  /* .solution_image {
  display: none;
} */

  .solution_items,
  .resource_items {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 20px;
  }

  /* services_list page start */

  .orders_1 {
    order: 2 !important;
  }

  .orders_2 {
    order: 1 !important;
  }

  .service_perticular_image .ecomm1 {
    position: inherit;
    width: 100%;
    /* top: 78px; */
    right: 0;
  }

  .service_perticular_image .document {
    position: inherit;
    width: 80%;
    /* top: 78px; */
    right: 0;
  }

  .air_fright {
    position: inherit;
    width: 100%;
    top: 250px;
  }

  #B2C .service_perticular_image::before {

    width: 500px;
    height: 600px;
    background-size: contain;
    right: 0;
    top: 0;
  }

  #Full_Truck .service_perticular_image::before {

    width: 100%;
    height: 100%;
    background-size: contain;
    /* left: 0; */

    top: 0;
  }

  .FullTruck {
    position: relative;
    width: 100%;
    /* margin-top: 5rem; */
  }

  .parttruck {
    position: relative;
    width: 70%;
    /* right: -117px; */

  }

  #Part_Truck .service_perticular_image::before {

    width: 400px;
    height: 500px;
    background-size: cover;
    right: 0;
    background-repeat: no-repeat;
    top: 0;
  }

  .hirefleet {
    position: relative;
    width: 100%;
    /* margin-top: 5rem; */
  }

  .all_serives_white {

    padding: 1rem;

  }

  .all_services p {
    font-size: 10px
  }

  .btn_newletter {

    right: 7%;
  }

  .customer_connect {
    padding: 0 !important;
    height: 300px !important;
  }

  .shiping_now_inside {
    height: 280px;
    padding: 0;

  }

  .colling p {
    margin-bottom: 2rem;
    font-size: var(--main-paragragh-tablet);
  }

  .contact_section,
  .contact_signup,
  .media_section,
  .aboutus_section {
    height: 100% !important;
  }

  .contact_section .right_side img {
    width: 100%;
    margin-top: 3rem;
  }

  .inner-banner .service_top_content h4,
  .contact_section h4,
  .media_section h4 {
    font-size: 28px;
    color: #ffffff;
  }

  .contactus_form {
    margin-top: 20px;
  }

  .magzine_section {
    padding: 2rem;
  }

  .Innovation h4 {

    font-size: 28px;
  }

  .Innovation p {
    font-size: var(--main-paragragh-tablet);
  }

  .aboutus_section h2 {
    font-size: 50px;
  }

  .vission_style .white_bg {
    width: 100%;
  }

  .foundation_2017 {
    left: 40%;
  }

  .foundation_2018 {

    left: 25%;
  }

  .foundation_2020 {
    width: 35%;
    right: 0;
  }

  .foundation_2019 {
    width: 25%;
    left: 0;
    bottom: -30%;
  }

  .wrapper_future {

    font-size: var(--main-paragragh-tablet);
  }

  .future_section h1 {
    font-size: var(--main-card-heading-tablet);
  }

  .future_section {
    background-position: right !important;
  }

  .service_slider {

    padding: 2rem 0;
  }

  .service_slider .box-area {

    height: 50vh;

  }

  .service_slider h4,
  .shiping_now_inside_bussiness h4 {

    font-size: 30px;

  }

  .shiping_now_inside_bussiness {
    padding: 6rem 2rem;
    display: flow;
  }

  .foundation_2021 {

    width: 188px;
    padding: 15px;
    position: absolute;
    right: 112px;
    bottom: 62%;
  }

  /* .hero-banner::after {
    content: "";
    background: url("/public/image/home_banner_pattern_full_1.png");
    background-repeat: no-repeat;
    position: absolute;
    top: -10%;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5;
  }

  .hero-banner::before {
    content: "";
    background: url("/public/image/home_banner_pattern_full.png");
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    z-index: -1;
  } */

  .inner-banner {

    height: 100% !important;

  }

  .all_rounder_gradient_box {

    height: 120px;

  }

  .stages_section img {
    width: 100%;
  }

  .reduce_ndr_section .ndr-mangemnet-part::after,
  .ndr-mangemnet-youtube::after {

    right: 0;
    top: auto;
    bottom: 0;

  }

  .main_difference {
    height: 100% !important;
  }

  .weight_stages::after {

    right: -161px;
    top: -350px;

  }

  .weight_stages .row {
    margin-bottom: 4rem !important;
  }

  .early_cod {
    padding: 3rem !important;
  }

  .early-numbers_pic img {
    width: 100%;
  }

  .early_cod::before {

    left: -23%;

  }

  .cod-connect::after {

    right: -41%;
    top: -58%;

  }

  .cod-connect,
  .api-connect {
    padding: 2rem;
  }

  .pickup_process {
    padding: 3rem !important;
  }

  .wrapper_multiple_connect::before,
  .wrapper_multiple_connect::after {

    width: 150px;
    height: 150px;

  }

  .wrapper_multiple_connect {
    padding: 3rem;
  }

  .shiping-rate_cal::after,
  .shiping-rate_cal::before {
    width: 250px;
    height: 250px;
    display: none;
  }

  .inside_connect p {
    font-size: var(--main-paragragh-tablet);

  }

  .Integration_steps::after {
    right: -20%;
  }

  .Integration_steps::before {
    left: -20%
  }


  .tracking_icon {


    position: absolute !important;
    left: 10%;
    /* top: 0; */
    /* bottom: 10px; */
  }

  .order-details {
    position: relative;
  }

  .scan_details {
    flex-wrap: wrap;
    /* gap: 20px; */

  }

  .scan_tracking .status_scan {
    width: 100%;

  }

  .all_rounder_gradient_box {
    width: 60%;
    height: 100px;

  }

  .tracking_action {
    width: 100% !important;
  }

  .scan_tracking {

    padding: 2rem 2rem 2rem 10rem;
  }

  .breadcrumb a {

    font-size: 14px;

    padding: 0 25px 0 33px;
    position: relative;
  }

  .testimonal-card {

    padding: 20px;

  }

  .tes-content p {
    font-size: 14px;
  }

  .logins-buttons {
    display: inherit;
  }

  .outline-login {
    width: 100%;
    margin-top: 1rem;
    background: #fff !important;
    border: 2px solid #000;
    padding: 10px 15px;
    border-radius: 4px;
    /* box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px; */
  }

  .button-reg {
    align-items: center;
    border-radius: 4px;
    background: linear-gradient(97deg, #4656a3 1.25%, #7939cc 102.15%);
    border: 0;
    /* border-radius: 8px; */
    /* box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px; */
    /* font-weight: 600 !important; */
    color: #ffffff !important;
    padding: 12px 16px !important;
    font-size: 15px !important;
    width: 100%;
    margin-top: 10px;
  }
}

/* for all tablet screen device */


/* for all laptop screen device */
@media screen and (min-width:1024px) {
  /* .container-fluid {
    padding-left: 70px !important;
    padding-right: 70px !important;
  } */

  .home_slider .box-area .active {
    width: 50%;

  }


  .hero-banner h1 {
    font-size: 30px !important;

  }




  Nav .nav-link {

    font-size: 15px;

    margin-right: 12px;

  }

  /* 
  .outline-login {
    padding: 8px 14px !important;
    font-size: 14px !important;
  } */

  /* .button-reg {
 
    font-size: 14px !important;
  } */

  .hero-banner {
    padding-top: 6rem;
  }

  .home_slider .box {
    width: 80px;
  }



  /* .shiping_partner {
    padding: 3rem 0 !important;
  } */

  .partner-trust h4,
  .testimonials-section h4,
  .shiping_partner h4,
  .dashboard_section h4 {
    font-size: 32px !important;

  }

  .order-status-section h4,
  .process-section h4,
  .rate-calculator2 h4,
  .benifits_customer h4,
  .herocard-section h4,
  .services_componets h4,
  .conversionslider h4,
  .integration h4,
  .blog-section h4,
  .stages_section h4,
  .dashboard_section h4 {
    font-size: 40px;
    font-weight: bold;
    color: #0b0757;
    margin-bottom: 2rem;
  }

  .process-section .client,
  .process-section .Orders,
  .process-section .Experts,
  .process-section .Deliveries,
  .process-section .Integrations,
  .process-section .pincodes {
    padding: 40px 15px !important;
  }

  .working-featured p {
    font-size: var(--main-paragragh-laptop)
  }

  .working-featured h3 {
    font-size: 25px;

  }

  .working-featured h3:after {
    top: 75px;
  }


  .order-status-parts h6 {
    font-size: 15px;
    margin: 0;

  }

  .order-status-parts p {
    font-size: var(--main-paragragh-laptop);
  }

  .order-status-parts img {
    width: 20%;
    margin-bottom: 10px;
  }

  .status_content {
    position: relative;
    padding-top: 20px;
  }

  .order-status-parts .status_content::before {
    content: "";

    width: 40px;
    height: 40px;
    top: 40%;
    right: -28%;

  }




  .glass_content p {

    font-size: var(--main-paragragh-laptop);
  }

  .services_componets .cards_services .cards_glass {
    padding: 18px 0 !important;
  }

  .glass_content {

    margin: 0 10px;

  }



  .integration_picture_magento {
    font-size: 14px;
  }


  .rate-content ul li,
  .rate-content p {
    font-size: var(--main-paragragh-laptop);
  }

  /* .margin_top_bottom {
    margin-top: 2rem;
  } */


  .bg_benifits_content p,
  .herocard-section .choose_wrapper p {
    font-size: var(--main-paragragh-laptop);
    margin: 0;
  }

  .dashboard_section {
    padding: 2rem !important;
  }

  .blog-content h4 {
    font-size: 18px !important;
  }

  /* .home_slider .box .image_btn {
    position: absolute;
    top: 50%;
    background: #fff;

    font-weight: 600;
    margin: 0;
    border-radius: 50px;
    font-size: 20px;
    transform: rotate(90deg);
    text-align: center;
  } */
  /* 
  .home_slider .box.active .image_btn {
    position: absolute;
    top: -25px;
    background: #fff;
    padding: 8px 15px;

    left: -50px;
    margin: 0;
    border-radius: 50px;
    font-size: 20px;

  } */

  .partner-trust {
    padding: 4rem 0;
  }

  .partner-trust .react-multi-carousel-item {
    margin-right: 15px;
  }

  .glass_content {
    margin: 0 10px;
  }

  .blog-content h3 {
    font-size: 18px !important;
  }

  /* .order-status-parts h6,
  .cards_glass h5,
  .bg_benifits_card h6,
  .conversion_content h6,
  .working-featured h3 {
    font-size: var(--main-card-heading-latop);
  } */

}


/* for all laptop screen device */


/* for all large laptop screen device */
@media screen and (min-width:1280px) and (max-width:2660px) {
  .hero-banner h1 {
    font-size: 48px !important;

  }



  .order-status-parts h6 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .order-status-parts p {
    font-size: 15px;
  }

  /* .shiping_partner {
    padding: 6rem 0 !important;
  } */

  .partner-trust {
    height: 100%;
  }

  /* ::placeholder {
    font-size: 14px;
  } */

}

/* for all large laptop screen device */





/* mac laptop screen */


/* mac laptop screen */

/* //dashboard page */
.features_dashboard ul li {
  margin-bottom: 20px;
  color: #7939CC;
}

.features_dashboard {
  background: url("/public//image/dashboard/3.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.features_dashboard h2 {
  font-size: 35px;
  font-weight: bold;
  color: #7939CC;
}

.coupons_available h3 {
  font-size: 35px;
  color: #fff;
  /* border-bottom: 2px solid #fff; */
}

.coupons_available .main_wrapper {
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.coupons_available .coupons_card {
  gap: 20px;
  /* padding: 10px; */
  display: flex;
  justify-content: space-between;
}

.main_wrapper .layout_left {
  padding: 30px;
  width: 82%;

}

.coupons_available .layout_right {
  width: 18%;
  background-color: #DAC3E8;
  border-radius: 0 30px 30px 0;
  border-left: 2px dashed #7939CC;
  padding: 7%;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;
}

.coupons_available .layout_right p {
  transform: rotate(90deg);
  color: #7939CC;
  font-weight: 600;
  font-size: 20px;

}

.coupons_available .layout_right::before {
  width: 30px;
  content: "";
  background: #574db0;
  position: absolute;
  top: 0;
  height: 30px;
  /* z-index: 2; */
  border-radius: 50%;
  right: 15%;
  top: -15px;
}

.coupons_available .layout_right::after {
  width: 30px;
  content: "";
  background: #574db0;
  position: absolute;
  bottom: 0;
  height: 30px;
  /* z-index: 2; */
  border-radius: 50%;
  right: 15%;
  bottom: -15px;
}

.coupons_card .coupon_image {
  width: 50%;
}

.coupons_card .coupon_content {
  width: 50%;
}

.coupon_content ul {
  font-size: 12px;
  color: #828282;

  /* padding: 0; */
}

.coupon_content h6 {
  font-size: 17px;
}

@media (max-width: 767px) {
  .coupons_available {
    padding: 2rem 0 !important;
  }

  .coupons_available h3 {
    font-size: 25px;

  }

  .main_wrapper .layout_left,
  .coupons_card .coupon_content {

    width: 100%;
  }

  .coupons_available .coupons_card {

    display: block;

  }

  .wrapper_dashborad h2 {
    font-size: 30px;

  }

  .wrapper_dashborad {

    margin-top: 0;
  }

  .termcondition,
  .applicationpPrivacypolicy,
  .Useinformation,
  .termconnect {

    padding: 1.5rem;

  }

  .applicationpPrivacypolicy::before,
  .Useinformation::before,
  .information_security::before {
    display: none;

  }

  .disclosure_information {

    background-image: none !important;
  }

  .features_dashboard {

    background-image: none;
  }

  .stages_section img {
    width: 75%;

  }

  .stages_images {
    text-align: center;
  }

  .footer_accordion {
    display: block;
  }

  .footer-section .footer_about {
    display: none;
  }

  .footer-section .accordion-button:not(.collapsed) {
    background-color: transparent !important;
  }

  .footer-section button.accordion-button {
    font-size: 18px !important;
    background: transparent !important;
    color: #fff !important;
    padding-left: 0;
  }

  .footer-section .accordion-item {
    margin: 0;
    box-shadow: none !important;
    background: transparent !important;
  }

  .footer-section .accordion .accordion-item .accordion-body {
    padding: 0;
  }

  .footer-section .accordion-button::after {

    background-image: url("/public/image//footer_accordance_arrow.png") !important
  }

  .footer-section .accordion-item .accordion-header {
    margin: 0;
    border-bottom: 2px solid #fff;
  }
}

/* //dashboard page */

.wrapper_dynamic_rates {
  display: grid;
  grid-template-columns: 2fr (1fr);
  /* justify-content: space-between; */
  gap: 30px;
  /* background-color: #fff; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  /* padding: 20px;
  margin: 20px; */
}

.card-header {
  padding: 0.5rem !important;
}

.flex_dynamic_rates img {
  width: 18% !important;
  height: 18%;
}

.flex_dynamic_rates {
  display: flex;
  /* justify-content: space-around; */
  gap: 20px;
}

.flex_dynamic_rates .title {
  text-transform: uppercase !important;
  color: #7939cc;
  font-weight: 700;
  font-size: 14px;
  /* white-space: nowrap; */
}

.body_rate_cal {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.body_rate_cal li span {
  position: absolute;
  right: 0;
  width: 100px;
  text-align: right;

}

.body_rate_cal li a {
  font-size: 11px;
}

.body_rate_cal li {
  font-size: 14px;
  line-height: 2;
}

.body_rate_cal li i {
  font-size: 10px;
  margin-right: 5px;
}

.accordion-header .flex_dynamic_rates p {
  font-size: 15px;
  font-weight: bold;
  color: #000;

}

.accordion-button:focus {
  border-color: none !important;

  box-shadow: none !important;
}

.fixed_height {
  /* height: 700px !important;
  overflow-y: scroll; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  background: #fff;
  /* padding: 20px; */
}



/* .grids{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

} */
.fa-solid.fa-times {
  margin: auto !important;
  cursor: pointer !important;
}

.rate_skelton {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin: 10px 0;
}

.skelton_image {
  display: flex;
  gap: 15px;
  border: 1px solid rgba(0, 0, 0, .125);
  background-color: rgba(0, 0, 0, .02);
  padding: 10px;
  border-radius: 2px !important;
}

.Charges_Bifurcation {
  background: linear-gradient(97deg, #4656a3 1.25%, #7939cc 102.15%) !important;

  color: #fff !important;
  font-size: 12px !important;
  padding: 6px !important;
}

.btn:focus {
  outline: 0 !important;
  box-shadow: none !important
}

.fixed_height .card-header {
  border: none !important;
}

.fixed_height .card {
  border: 1px solid #4656a3 !important;
}

@media (max-width: 320px) {
  .trux-logo {
    width: 180px !important;
  }
}